import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KeyboardEvent, useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { disableNext, enableNext, flexReallocate, getCustomPopUpURL, getFlex, setFlexBeneficiaryBenefitID, updateFlex } from "../../actions";
import { AlertComponent, ButtonComponent, ModalComponent } from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils, USER_TOKEN } from "../../utils";
import { CustomToolTipIcon } from "../../assets/icons";
interface Benefit {
  benefitID: number;
  name: string | null;
  isDefault: boolean;
  sequence: number;
  flexAmount: number;
  flexMessage: string | null;
  cantContributeVisible: boolean;
  cantContributeText: boolean;
  isFlexBenefitMessageVisible: boolean;
  flexAmountReadOnly: boolean;
  isHelpVisible: boolean;
  helpLink: string | null;
  minimumAllocationNotValidMessage: string | null;
  maximumAllocationNotValidMessage: string | null;
  decreaseNotAllowedMessage: string | null;
  increaseNotAllowedMessage: string | null;
  isDeductionsVisible: string | boolean;
  deduction: any;
  isEarnedFlexAmountVisible: boolean;
  earnedFlexAmount: string;
  isFlexBenefitAdditionalDetailsVisible: boolean;
  flexBenefitAdditionalDetailsMessaging: string | null;
  isTaxesVisible: boolean;
  tax: any;
  isMatchVisible: boolean;
  match: string | null;
  needBeneficiary: boolean;
  beneficiaryList: string[];
  unitsValidatorErrorMessage: string | null;
  flexBenefitErrorMessage: string | null;
}

interface ReallocateFlex {
  benefitName?: string;
  before?: string;
  after?: string;
}

export const FlexDollars = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { flexDollars } = useSelector((state: RootState) => state.benefit);
  const { contentFooter, helpContentResponse } = useSelector((state: RootState) => state.common);

  const footerContent = contentFooter?.contentResource?.contents;
  const helpContent = helpContentResponse?.result?.content;

  const [flexBenefitList, setFlexBenefitList] = useState<number[]>([]);
  const [beneficiaryID, setBeneficiaryID] = useState(0);
  const [showBeneficiary, setShowBeneficiary] = useState(false);
  const [benefitFlexes, setBenefitFlexes] = useState<string[]>([]);
  const contents = flexDollars?.contentResource?.contents || {};

  const pageTitle = flexDollars?.pageTitle;
  const flexAssignmentMessage = flexDollars?.flexAssignmentMessage;
  const labelErrorFlexRemaining = flexDollars?.labelErrorFlexRemaining;
  const labelErrorFlexOverLimit = flexDollars?.labelErrorFlexOverLimit;
  const confirmToReallocate = flexDollars?.confirmToReallocate;
  const flexDollarsRemaining = flexDollars?.flexDollarsRemaining;
  const labelFlexDollarsRemaining = flexDollars?.labelFlexDollarsRemaining;
  const proceedToNextStepErrorMessage = flexDollars?.proceedToNextStepErrorMessage;

  const allowCreditsToReallocate = flexDollars?.allowCreditsToReallocate;
  const reallocateFlexInfo = flexDollars?.reallocateFlexInfo;

  const toolTipIcon = ObjectUtils.extractLabelFromObject(footerContent, "ToolTipIcon");
  const questionMarkIcon = ObjectUtils.extractLabelFromObject(footerContent, "QuestionMarkIcon");

  const lblViewBeneficiaries = ObjectUtils.extractLabelFromObject(contents, "lblViewBeneficiaries");
  const flexInputWatermark = ObjectUtils.extractLabelFromObject(contents, "FlexInputWatermark");
  const commonFlexMessage = ObjectUtils.extractLabelFromObject(contents, "commonFlexMessage");
  // const [didUpdateShowBeneficiary, setDidUpdateShowBeneficiary] = useState(false);

  const [flexInfo, setFlexInfo] = useState({
    allowCreditsToReallocate: false,
    reallocateFlexInfo: {}
  });

  const [flag, setFlag] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [toolTipMessage, setToolTipMessage] = useState("");
  const [showHelpContent, setShowHelpContent] = useState(false);
  const [inputValue, setInputValue] = useState<number>(0);

  const search = new URLSearchParams(location.search)?.get("GroupID") ?? "";
  // console.log("Parsed GroupID:", search);
  const [flexGroupID, setFlexGroupID] = useState(0);

  useEffect(() => {
    async function fetchFlexDoller() {
      if (search) {
        setFlexGroupID(parseInt(search));
        dispatch(await getFlex(parseInt(search), true));
      }
    }

    fetchFlexDoller();
    return () => {
      // Anything in here is fired on component unmount.
      enableNext();
    };
  }, [dispatch, search]);

  useEffect(() => {
    if (flexDollars?.benefitFlexes !== benefitFlexes) {
      setBenefitFlexes(flexDollars?.benefitFlexes);
      const newFlexBenefitList: number[] = []; // Initialize as an empty array
      let newBeneficiaryID: number = beneficiaryID;
      let newShowBeneficiary = showBeneficiary;
      let didUpdateShowBeneficiary = false;

      flexDollars?.benefitFlexes?.forEach((benefit: Benefit) => {
        newFlexBenefitList[benefit?.benefitID] = benefit.flexAmount ? benefit.flexAmount : 0;
        if (benefit.needBeneficiary) {
          newBeneficiaryID = benefit.benefitID;
          newShowBeneficiary = true;
          didUpdateShowBeneficiary = true;
        }
      });

      if (!didUpdateShowBeneficiary && newShowBeneficiary) {
        newShowBeneficiary = false;
      }

      flexDollars?.canProceedToNextStep ? enableNext() : disableNext();
      setFlexBenefitList(newFlexBenefitList);
      setBeneficiaryID(newBeneficiaryID);
      setShowBeneficiary(newShowBeneficiary);

      setFlexInfo({
        allowCreditsToReallocate,
        reallocateFlexInfo
      });

      const errorMsgElement = document.getElementById("errorMsg");
      if (errorMsgElement !== null && flag) {
        errorMsgElement.focus();
      }
    }
  }, [allowCreditsToReallocate, beneficiaryID, benefitFlexes, flag, flexDollars?.benefitFlexes, flexDollars?.canProceedToNextStep, reallocateFlexInfo, showBeneficiary]);

  const withPadding = (benefit: Benefit) => {
    if (benefit.tax != null || benefit.deduction != null || benefit.isEarnedFlexAmountVisible || benefit.isFlexBenefitAdditionalDetailsVisible || benefit.cantContributeVisible) {
      return false;
    }

    return true;
  };

  const getFormattedValue = (value) => {
    const precision = flexDollars.precision || "2";
    const localestring = USER_TOKEN?.get()?.lang?.substring(0, 2);

    const regexPattern = localestring === "en" ? /[^\d.]/g : /[^\d,]/g;
    const thousandSeparatorRegex = localestring === "en" ? /(\..*)\./g : /(\,.*)\,/g;
    const decimalPlacesRegex = localestring === "en" ? /(\.[\d]{2})./g : /(\,[\d]{2})./g;

    let formattedValue = value.replace(regexPattern, "").replace(/(^(0|1-9))[\d]/g, "$1");

    if (precision === "2") {
      formattedValue = formattedValue.replace(thousandSeparatorRegex, "$1").replace(decimalPlacesRegex, "$1");
    }

    return formattedValue;
  };

  const handleFlexValueUpdate = async (event: any) => {
    const updatedFlexBenefitList = {
      ...flexBenefitList,
      [event.target.id]: event.target.value
    };

    const requestBody = {
      flexGroupID: flexGroupID,
      flexItem: {
        flexBenefitID: event.target.id,
        flexAmount: updatedFlexBenefitList[event.target.id] || "0"
      }
    };

    await updateFlex(requestBody);
  };

  // Define handleFocusInput function
  const handleFocusInput = (event) => {
    const inputID = event.target.id;
    const inputValue = flexBenefitList[inputID];
    setInputValue(inputValue);
  };

  // Define handleBlurInput function
  const handleBlurInput = (event: any) => {
    const targetID = event.target.id;
    if (inputValue !== flexBenefitList[targetID]) {
      handleFlexValueUpdate(event);
    }
  };

  const handleShowToolTip = (msg: any) => {
    setShowTooltip(true);
    setToolTipMessage(msg);
  };

  const handleShowHelp = (lnkHelp: string | null): void => {
    const helpLink = `Help/${lnkHelp}`;
    if (helpLink) {
      dispatch(
        getCustomPopUpURL(helpLink, () => {
          setShowHelpContent(true);
        })
      );
    }
  };

  const handleClose = () => {
    setShowTooltip(false);
    setShowHelpContent(false);
  };

  const handleEditAction = (benefitID: number) => {
    setFlexBeneficiaryBenefitID(benefitID, "", () => {
      navigate("/Beneficiaries");
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLSpanElement>, functionType: number, parameter: string | null) => {
    const { code } = event;
    switch (code) {
      case "Enter": {
        if (functionType === 0) {
          handleShowToolTip(parameter);
        } else {
          handleShowHelp(parameter);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleChange = async () => {
    setFlexInfo({
      ...flexInfo,
      allowCreditsToReallocate: !flexInfo?.allowCreditsToReallocate
    });

    dispatch(await flexReallocate({ allowCreditsToReallocate: !flexInfo?.allowCreditsToReallocate }));
  };

  const onInputChange = (event: any) => {
    const value = event.target.value;

    const validatedValue = getFormattedValue(value);

    const updatedFlexBenefitList = { ...flexBenefitList, [event.target.id]: validatedValue };

    setFlexBenefitList(updatedFlexBenefitList);
    setFlag(true);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" || event.charCode === 13) {
      handleFlexValueUpdate(event);
    }
  };

  // const isCommonFlexMessage = contents.some((item: { keyName: string }) => item.keyName === "commonFlexMessage");
  const isCommonFlexMessage = Array.isArray(contents) && contents.some((item) => item.keyName === "commonFlexMessage");

  const flexBenefitRows = flexDollars?.benefitFlexes?.map((flexBenefit: Benefit) => {
    const flexBenefitAmount = flexBenefitList[flexBenefit.benefitID] || "";

    return (
      <div key={`div-${flexBenefit?.benefitID}`} className={`py-3 py-md-4 border-top ${withPadding(flexBenefit) ? "customcol" : "customcol-no-padding"}`}>
        <Row className={`align-items-center`}>
          <div className="col-8 col-sm-9">
            {flexBenefit?.name}
            <span className={`infoicon ps-2`}>
              {flexBenefit?.flexMessage && (
                <span
                  className="tooltip-icon icon-action"
                  role="button"
                  tabIndex={0}
                  aria-label={toolTipIcon.textToRead}
                  onKeyDown={(event) => handleKeyDown(event, 0, flexBenefit.flexMessage)}
                  onClick={() => {
                    handleShowToolTip(flexBenefit.flexMessage);
                  }}
                >
                  <CustomToolTipIcon />
                </span>
              )}
              {flexBenefit.isHelpVisible && (
                <span
                  id="helpBtn"
                  className="tooltip-icon icon-action"
                  role="button"
                  tabIndex={0}
                  aria-label={questionMarkIcon.textToRead}
                  onKeyDown={() => handleShowHelp(flexBenefit?.helpLink)}
                  onClick={() => handleShowHelp(flexBenefit.helpLink)}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </span>
              )}
            </span>
          </div>
          <div className="col-4 col-sm-3">
            <Form.Control
              name="benefitAmount"
              id={flexBenefit?.benefitID.toString()}
              className="login-input-field edit-input-field"
              value={flexBenefitAmount}
              onChange={onInputChange}
              onKeyDown={handleKeyPress}
              disabled={flexBenefit.flexAmountReadOnly}
              aria-label={flexBenefit?.name || ""}
              aria-disabled={flexBenefit.flexAmountReadOnly}
              placeholder={flexInputWatermark.display}
              onFocus={handleFocusInput}
              onBlur={handleBlurInput}
            />
          </div>
        </Row>
        {flexBenefit.flexBenefitErrorMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.flexBenefitErrorMessage
            }}
          />
        )}
        {/* Other error messages */}
        {flexBenefit.minimumAllocationNotValidMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.minimumAllocationNotValidMessage
            }}
          />
        )}
        {flexBenefit.maximumAllocationNotValidMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.maximumAllocationNotValidMessage
            }}
          />
        )}
        {flexBenefit.decreaseNotAllowedMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.decreaseNotAllowedMessage
            }}
          />
        )}
        {flexBenefit.increaseNotAllowedMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.increaseNotAllowedMessage
            }}
          />
        )}
        {flexBenefit.unitsValidatorErrorMessage !== null && (
          <span
            className={`ml-error float-none mb-0 pt-3 d-block`}
            role="alert"
            dangerouslySetInnerHTML={{
              __html: flexBenefit.unitsValidatorErrorMessage
            }}
          />
        )}

        {!withPadding(flexBenefit) && <div className="mt-3 mt-md-4">{/* Tax, deduction, and other details */}</div>}
        {!withPadding(flexBenefit) && (
          <div className="mt-3 mt-md-4">
            {flexBenefit.tax != null && (
              <div className={`child-flex pb-3 pb-md-4 ps-md-5 border-top`}>
                <Row className={`justify-content-between pt-4 m-0`}>
                  <Col className="col-8 col-sm-9 p-0">
                    {flexBenefit?.tax?.flexBenefitTaxNote && (
                      <p
                        className={`m-0`}
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit?.tax?.flexBenefitTaxNote
                        }}
                      />
                    )}
                  </Col>
                  <Col className="col-4 col-sm-3 p-md-0 text-end">
                    <strong>{flexBenefit?.tax?.taxes}</strong>
                  </Col>
                </Row>
              </div>
            )}
            {flexBenefit.deduction != null && (
              <div className={`child-flex pb-3 pb-md-4 ps-md-5 border-top`}>
                <Row className={`py-3 py-md-4 m-md-0`}>
                  <Col className="col-12 ps-md-0">
                    {flexBenefit?.deduction?.flexDeductionTitle && (
                      <p
                        className={`m-0`}
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit?.deduction?.flexDeductionTitle
                        }}
                      />
                    )}
                  </Col>
                </Row>
                <Row className={`justify-content-between py-3 py-md-4 border-top m-0`}>
                  <Col className="col-8 col-sm-9 p-0">
                    {flexBenefit?.deduction?.labelAnnualDeduction && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.labelAnnualDeduction
                        }}
                      ></div>
                    )}
                  </Col>
                  <Col className="col-4 col-sm-3 text-end">
                    <strong>{flexBenefit.deduction.annualDeduction}</strong>
                  </Col>
                </Row>
                <Row className={`justify-content-between py-3 py-md-4 border-top m-0`}>
                  <Col className="col-8 col-sm-9 p-0">
                    {flexBenefit?.deduction?.labelPerPayDeduction && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.deduction.labelPerPayDeduction
                        }}
                      ></div>
                    )}
                  </Col>
                  <Col className="col-4 col-sm-3 text-end">
                    <strong>{flexBenefit.deduction.perPayDeduction}</strong>
                  </Col>
                </Row>
                <Row className={`justify-content-between pt-3 pt-md-4 border-top m-0`}>
                  <Col className="col-8 col-sm-9 p-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: flexBenefit.deduction.labelBalance
                      }}
                    ></div>
                  </Col>
                  <Col className="col-4 col-sm-3 text-end">
                    <strong>{flexBenefit.deduction.accountBalance}</strong>
                  </Col>
                </Row>
              </div>
            )}
            {flexBenefit.isEarnedFlexAmountVisible && (
              <div className={`child-flex pb-3 pb-md-4 ps-md-5 border-top`}>
                <Row className={`justify-content-between pt-4 m-0`}>
                  <Col className="col-2 offset-10 text-end p-0">
                    <strong>{flexBenefit.earnedFlexAmount}</strong>
                  </Col>
                </Row>
              </div>
            )}
            {flexBenefit.isFlexBenefitAdditionalDetailsVisible && (
              <div className="child-flex pb-3 pb-md-4 ps-md-5 border-top">
                <Row className={`justify-content-between pt-4 m-0`}>
                  <Col className="col-12 p-0">
                    {flexBenefit?.flexBenefitAdditionalDetailsMessaging && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: flexBenefit.flexBenefitAdditionalDetailsMessaging
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </div>
            )}
            {flexBenefit.cantContributeVisible && (
              <div className="child-flex pb-3 pb-md-4 ps-md-5 border-top">
                <Row className={`justify-content-between pt-4 m-0`}>
                  <Col className="col-12 p-0">{flexBenefit.cantContributeText}</Col>
                </Row>
              </div>
            )}
          </div>
        )}

        {flexBenefit.needBeneficiary && (
          <div className="mt-md-2 mb-md-2 d-flex justify-content-end mt-2">
            <ButtonComponent
              btndata={[
                {
                  variant: "outline-secondary",
                  display: lblViewBeneficiaries.display,
                  onSubmit: () => handleEditAction(flexBenefit?.benefitID)
                }
              ]}
            />
          </div>
        )}
      </div>
    );
  });

  /* Flex re-allocation */
  const reallocateFlexHeaders = reallocateFlexInfo?.header;
  const payrollDeductionBenefits = reallocateFlexInfo?.payrollDeductionBenefits;
  const reallocateFlexTotal = reallocateFlexInfo?.total;
  const title = reallocateFlexInfo?.title;
  const payrollDeductionMessage = reallocateFlexInfo?.payrollDeductionMessage;

  // const flexColumnCount = ((reallocateFlexHeaders.benefitName && 1) || 0) + ((reallocateFlexHeaders.before && 1) || 0) + ((reallocateFlexHeaders.after && 1) || 0);

  const reallocateFlexRows = payrollDeductionBenefits?.map((reallocateFlex: ReallocateFlex, index: number) => (
    <Row className="singleitem justify-content-md-center" key={`div-flexRow-${reallocateFlex.benefitName}-${index}-ID`}>
      {reallocateFlex.benefitName && (
        <Col xs lg="3" data-title={reallocateFlex.benefitName}>
          <span className="visuallyhidden first">{reallocateFlexHeaders.benefitName}</span>
          <span>{reallocateFlex.benefitName || "\u00A0"}</span>
        </Col>
      )}
      {reallocateFlex.before && (
        <Col xs lg="2" data-title={reallocateFlex.before} style={{ display: "flex", justifyContent: "right" }}>
          <span className="visuallyhidden">{reallocateFlexHeaders.before}</span>
          <span className="float-right">{reallocateFlex.before || "\u00A0"}</span>
        </Col>
      )}
      {reallocateFlex.after && (
        <Col xs lg="2" data-title={reallocateFlex.after} style={{ display: "flex", justifyContent: "right" }}>
          <span className="visuallyhidden">{reallocateFlexHeaders.after}</span>
          <span className="float-right">{reallocateFlex.after || "\u00A0"}</span>
        </Col>
      )}
    </Row>
  ));
  /* Flex re-allocation */

  const handleFlexModal = {
    showModal: showHelpContent || showTooltip,
    dialogClass: "ml-addnewdependent",
    clickHandler: handleClose,
    head: {
      modalTitle: "&nbsp;"
    },
    body: {
      confirmBoxData: {
        messagetext: (showHelpContent ? helpContent : "") + (showTooltip ? toolTipMessage : "")
        // messagetextclass: "ml-popupmessagetext align-left"
      }
    }
  };

  return (
    <div className="flexdollars">
      <div className="ml-titledescription pb-3">
        <h2 className={`m-0`}>{pageTitle}</h2>
      </div>
      <div className="ml-description pb-3">
        <p className={`mb-3`} dangerouslySetInnerHTML={{ __html: flexAssignmentMessage }} />
      </div>

      {labelErrorFlexRemaining && <AlertComponent propmessageType={`info`} propmessage={labelErrorFlexRemaining} />}

      {labelErrorFlexOverLimit && <AlertComponent propmessageType={`danger`} propmessage={labelErrorFlexOverLimit} />}

      {confirmToReallocate && (
        <div className="clearfix form-check confirmationcheck mb-3 mb-md-4">
          <input type="checkbox" key="confirmSelection" className="" id="confirmSelection" checked={flexInfo?.allowCreditsToReallocate} onChange={handleChange} />
          <label className="form-check-label warning" htmlFor="confirmSelection">
            {confirmToReallocate}
          </label>
        </div>
      )}

      <Card className={`flexdollarscard`}>
        <Card.Header>
          <Row>
            <Col className="col-xs-9">
              <h2>{labelFlexDollarsRemaining}</h2>
            </Col>
            <Col className="col-xs-3 text-end">
              <h2>{flexDollarsRemaining}</h2>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>{flexBenefitRows}</Card.Body>
        {proceedToNextStepErrorMessage && (
          <Card.Footer>
            {proceedToNextStepErrorMessage !== null && (
              <p
                role="alert"
                id="errorMsg"
                tabIndex={-1}
                className={`m-0 alert alert-danger`}
                dangerouslySetInnerHTML={{
                  __html: proceedToNextStepErrorMessage
                }}
              />
            )}
          </Card.Footer>
        )}
      </Card>

      {flexInfo?.allowCreditsToReallocate && reallocateFlexInfo && (
        <Card className="flexdollarscard">
          <Card.Header>
            <Row>
              <Col className="col-xs-9">
                <h2>{title}</h2>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <p className={`mb-3`} dangerouslySetInnerHTML={{ __html: payrollDeductionMessage }} />

            <Row className="justify-content-md-center font-weight-bold">
              <Col xs lg="3" data-title={reallocateFlexHeaders.benefitName}>
                <span>{reallocateFlexHeaders.benefitName || "\u00A0"}</span>
              </Col>
              <Col xs lg="2" data-title={reallocateFlexHeaders.before}>
                <span className="float-right">{reallocateFlexHeaders.before || "\u00A0"}</span>
              </Col>
              <Col xs lg="2" data-title={reallocateFlexHeaders.after}>
                <span className="float-right">{reallocateFlexHeaders.after || "\u00A0"}</span>
              </Col>
            </Row>
            {reallocateFlexRows}
          </Card.Body>
          <Card.Footer>
            <Row className="justify-content-md-center">
              {reallocateFlexTotal.benefitName && (
                <Col xs lg="3" className="col-xs-9">
                  <span className="visuallyhidden first">{reallocateFlexHeaders.benefitName}</span>
                  <span>{reallocateFlexTotal.benefitName}</span>
                </Col>
              )}
              {reallocateFlexTotal.before && (
                <Col xs lg="2" className="col-xs-9">
                  <span className="visuallyhidden">{reallocateFlexHeaders.before}</span>
                  <span className="font-weight-bold float-right">{reallocateFlexTotal.before}</span>
                </Col>
              )}
              {reallocateFlexTotal.after && (
                <Col xs lg="2" className="col-xs-9">
                  <span className="visuallyhidden">{reallocateFlexHeaders.after}</span>
                  <span className="font-weight-bold float-right">{reallocateFlexTotal.after}</span>
                </Col>
              )}
            </Row>
          </Card.Footer>
        </Card>
      )}

      {commonFlexMessage && commonFlexMessage.display && isCommonFlexMessage && <AlertComponent propmessageType={`warning`} propmessage={commonFlexMessage.display} />}

      <ModalComponent modalObject={handleFlexModal}></ModalComponent>
    </div>
  );
};

export default FlexDollars;
