import { ObjectUtils } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getBeneficiaryDeclaration, getBeneficiariesAll, finishEnrollmentForms, downloadFinishedEnrollmentFile, setPageSetting, setFlexBeneficiaryBenefitID } from "../../actions";
import { useCallback, useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { Accordion, Card, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AlertComponent, ButtonComponent, CardComponent, ConfirmedItemComponent, ModalComponent } from "../../components";
import { BeneficiariesAllModal } from "../../interfaces/formValidation";
import Beneficiaries from "./Beneficiaries";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { BeneficiaryConstants, navigateChangedState } from "../../constants";

export const BeneficiariesAll = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [beneficiariesAllModal, setBeneficiariesAllModal] = useState<BeneficiariesAllModal>({
    showEdit: false,
    benefitID: -1,
    viewDeclaration: false
  });

  const beneficiariesAll = useSelector((state: RootState) => state.beneficiary.beneficiariesAll);
  const beneficiariyForm = useSelector((state: RootState) => state.beneficiary.beneficiariyForm);
  const helpContent = useSelector((state: RootState) => state?.common?.helpContentResponse?.result || "");

  const getBeneficiariesAllData = useCallback(() => {
    dispatch(getBeneficiariesAll());
  }, [dispatch]);

  const getfinishEnrollmentFormsData = useCallback(() => {
    dispatch(finishEnrollmentForms());
  }, [dispatch]);

  useEffect(() => {
    //This gets app config data and save into redux store
    console.log("Here...1");
    
     getBeneficiariesAllData();
     getfinishEnrollmentFormsData();
  }, [getBeneficiariesAllData, getfinishEnrollmentFormsData]);

  const hideEdit = () => {
    getBeneficiariesAllData();
    getfinishEnrollmentFormsData();
    setBeneficiariesAllModal({
      ...beneficiariesAllModal,
      showEdit: false,
      benefitID: -1
    });
  };

  const getTotalPer = useCallback((beneficiary: any) => {
    let newTotal: any = 0;
    beneficiary &&
      beneficiary?.map((item: any) => {
        newTotal = item.percentage === "" ? null : item.percentage + parseInt(newTotal);
      });
    return isNaN(newTotal) ? null : newTotal;
  }, []);

  const renderBeneficiarieList = (title: any, editButton: any, contents: any, list: any) => {
    if (list && list.length > 0) {
      const thcName = ObjectUtils.extractLabelFromObject(contents, "thcName");
      const thcRelationship = ObjectUtils.extractLabelFromObject(contents, "thcFormRelationship");
      const thcTrustee = ObjectUtils.extractLabelFromObject(contents, "thcTrustee");
      const thcPercent = ObjectUtils.extractLabelFromObject(contents, "thcPercent");
      const thcTotal = ObjectUtils.extractLabelFromObject(contents, "thcTotal");

      //const noteMessage = `${title} <strong>${note ? note : ""}</strong>`
      const items: any = [];
      list?.map((item: any) => {
        items.push(
          <tr key={`div-${title}-${editButton?.linkID}-ID-${item?.beneficiaryName}`}>
            <td dangerouslySetInnerHTML={{ __html: `<strong>${item?.beneficiaryName}</strong><br />${item?.dateOfBirth ? item?.dateOfBirth : "n/a"}` }} />
            <td dangerouslySetInnerHTML={{ __html: item?.relationShip ? item?.relationShip : "n/a" }} />
            <td dangerouslySetInnerHTML={{ __html: item?.trustee ? item?.trustee : "n/a" }} />
            {item?.removedMsg === "" || item?.removedMsg == null ? (
              <td className="text-right">{item?.percentage}%</td>
            ) : (
              <td className="text-right">
                <span className="ml-mandatory-sign">({item?.removedMsg})</span> {item?.percentage}%
              </td>
            )}
          </tr>
        );
      });

      return (
        <>
          <div className="tbl-beneficiaries">
            <h3 className="title">{title}</h3>
            <Table>
              <thead>
                <tr>
                  <th scope="col">{thcName?.display}</th>
                  <th scope="col">{thcRelationship?.display}</th>
                  <th scope="col">{thcTrustee?.display}</th>
                  <th scope="col">{thcPercent?.display}</th>
                </tr>
              </thead>
              <tbody>{items}</tbody>
              <tfoot>
                <tr>
                  <td colSpan={3} className="text-right no-right-border">
                    <strong>{thcTotal?.display}</strong>
                  </td>
                  <td>
                    <strong className="m-0">{getTotalPer((list || []).length > 0 ? list : 0)}%</strong>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </>
      );
    }

    return null;
  };

  const renderNoBeneficiariesMessage = (title: any, message: any, thcPercent: any) => {
    return (
      <>
        <Card className={`ccard onfilebeneficiaries`}>
          <Card.Header>
            <div>
              <h2>{title}</h2>{" "}
            </div>
            <div>
              <h2>{thcPercent.display}</h2>
            </div>
          </Card.Header>
          {message && (
            <Card.Footer>
              {/* <>
                <div className="alert alert-secondary" role="alert" dangerouslySetInnerHTML={{ __html: message }} />
              </> */}
              <AlertComponent propmessageType={`secondary`} propmessage={message} />
            </Card.Footer>
          )}
        </Card>
      </>
    );
  };

  const beneficiaryNavigationClicked = (beneficiaryFormNavigateTo: any, fileName: any) => {
    downloadFinishedEnrollmentFile(beneficiaryFormNavigateTo, fileName);
  };

  const handleEditAction = (_benefitID: any) => {
    setBeneficiariesAllModal({
      ...beneficiariesAllModal,
      showEdit: true,
      benefitID: _benefitID
    });
    setFlexBeneficiaryBenefitID(_benefitID,"");
    dispatch({
      type: BeneficiaryConstants.GET_BENEFICIARIES,
      payload: null
    });
  };

  const handleBack = () => {
    setPageSetting("WELCOME");
    navigate("/", navigateChangedState);
  };

  /* E-Signature Start */
  const handleShowHelp = (btnNavigateTo: any) => {
    console.log("--> HELP LINK: ", btnNavigateTo);
    if (btnNavigateTo) {
      getBeneficiaryDeclaration(btnNavigateTo, () => {
        setBeneficiariesAllModal({
          ...beneficiariesAllModal,
          viewDeclaration: true
        });
      });
    }
  };

  const handleClose = () => {
    setBeneficiariesAllModal({
      ...beneficiariesAllModal,
      viewDeclaration: false
    });
  };

  /* E-Signature End */
  const beneficiaryBenefitsInfo = beneficiariesAll?.beneficiaryBenefitsInfo ? beneficiariesAll?.beneficiaryBenefitsInfo : [];
  const content = beneficiariesAll?.contentResource ? beneficiariesAll?.contentResource?.controlResource : {};
  const beneficiaryForms = beneficiariyForm?.beneficiaryForms;
  const isBeneficiaryFormVisible = beneficiariyForm?.beneficiaryFormsFormVisible;
  let beneficiaryFilename = "";
  if (isBeneficiaryFormVisible && beneficiaryForms && beneficiaryForms !== null) {
    beneficiaryFilename = beneficiaryForms.fileName ? beneficiaryForms.fileName : "BeneficiaryForm";
  }
  /* E-Signature Start */
  const isEsignable = beneficiariyForm?.isEsignable;
  const beneficiaryESign = beneficiariyForm?.beneficiaryESign;
  let beneficiaryESignFilename: any = "";
  if (isEsignable && beneficiaryESign && beneficiaryESign !== null) {
    beneficiaryESignFilename = beneficiaryESign.fileName ? beneficiaryESign.fileName : "BeneficiaryESignForm";
  }
  /* E-Signature End */
  //Beneficiary Form Configuration
  //const dangerType = "DANGER";
  const simpleButtonColor = "#f4f9f2";
  const downloadButtonLabel = beneficiariyForm?.downloadButtonLabel;

  const beneficiaryMessage = beneficiariesAll?.beneficiariesMessage;
  const noBeneficiaryBenefits = beneficiariesAll?.noBeneficiaryBenefits ? beneficiariesAll?.noBeneficiaryBenefits : "";
  const noCoverage = beneficiariesAll?.noCoverage ? beneficiariesAll?.noCoverage : "";
  //const contentres = beneficiariesAll?.contentResource ?beneficiariesAll?.contentResource?.contents : {}
  const contents = content?.Beneficiaries ? content?.Beneficiaries : {};
  // const pageTitle = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiaries")
  const thcPercent = ObjectUtils.extractLabelFromObject(contents, "thcPercent");
  const lblEdit = ObjectUtils.extractLabelFromObject(contents, "lblChoose");
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  const beneficiaryList: any = [];
  beneficiaryBenefitsInfo?.map((benefit: any, index: number) => {
    const head: any = {
      gridtitle: benefit?.benefitName,
      headerclass: "heading-white",
      showediticon: false
    };
    const onFile = renderBeneficiarieList(benefit?.lblOnFile, benefit?.editButton, contents, benefit?.beneficiaryCurrentInfo);
    const pending = renderBeneficiarieList(benefit?.pending, benefit?.editButton, contents, benefit?.beneficiaryPendingInfo);
    const contingentPending = renderBeneficiarieList(benefit?.lblContingentPending, benefit?.editButton, contents, benefit?.contingentBeneficiaryPendingInfo);
    const contingentOnFile = renderBeneficiarieList(benefit?.lblContingentOnFile, benefit?.editButton, contents, benefit?.contingentBeneficiaryCurrentInfo);
    const noBeneficiariesMessage = renderNoBeneficiariesMessage(benefit?.lblOnFile, benefit?.noBeneficiariesNote, thcPercent);
    const noContBeneficiariesMessage = benefit?.isContingentBeneficiaryOn ? renderNoBeneficiariesMessage(benefit?.lblContingentOnFile, benefit?.noBeneficiariesNote, thcPercent) : null;

    /* E-Signature Start */
    const { viewBeneficiaryDeclaration, removedBeneficiaryError } = benefit;
    /* E-Signature End */
    beneficiaryList.push(
      <section key={`div-${benefit?.benefitID}-ID-${benefit?.benefitName}-${index}`} className="mb-md-4">
        {beneficiariesAllModal?.showEdit && benefit?.benefitID === beneficiariesAllModal?.benefitID ? (
          <div className="clearfix">
            <Beneficiaries
              beneficiariesAll={true}
              benefitID={benefit?.benefitID}
              benefitTitle={benefit?.benefitName}
              hideEdit={hideEdit}
              handleCancelEdit={hideEdit}
              isCancelVisible={true}
            ></Beneficiaries>
          </div>
        ) : (
          <CardComponent
            key={`d-div-${benefit?.benefitID}-${index}`}
            gridObject={{
              cardClass: "pcard",
              head
            }}
          >
            {benefit?.noBeneficiariesNote ? (
              <>
                {noBeneficiariesMessage}
                {noContBeneficiariesMessage}
              </>
            ) : (
              <>
                {onFile}
                {contingentOnFile}
                {pending}
                {contingentPending}
              </>
            )}
            {removedBeneficiaryError && <AlertComponent propmessageType={`danger mb-2`} propmessage={removedBeneficiaryError} />}
            <br />
            <div className={"clearfix btnbox"}>
              <ButtonComponent
                btndata={[
                  {
                    variant: "outline-secondary btn-choosebeneficiary float-start",
                    disabled: benefit?.noEditableNote,
                    display: lblEdit?.display,
                    onSubmit: () => handleEditAction(benefit?.benefitID)
                  }
                ]}
              />
              {/* E-Signature Start */}
              {benefit?.isEsignable && benefit?.noEditableNote && <AlertComponent propmessageType={`info clearfix m-0 mb-3 mt-2 float-left`} propmessage={benefit.noEditableNote} />}
              {benefit?.isEsignable && !benefit?.noEditableNote && viewBeneficiaryDeclaration?.name && (
                <Link
                  to="#"
                  onClick={() => {
                    handleShowHelp(viewBeneficiaryDeclaration?.btnNavigateTo);
                  }}
                  aria-label={viewBeneficiaryDeclaration?.name}
                  className="float-right icon-action mt-3"
                  dangerouslySetInnerHTML={{ __html: viewBeneficiaryDeclaration?.name }}
                />
              )}
              {/* E-Signature End */}
            </div>
          </CardComponent>
        )}
      </section>
    );
  });

  const downloadInputData = [
    {
      messagetype: beneficiaryForms?.messageType,
      message: beneficiaryForms?.message,
      title: beneficiaryForms?.name,
      buttontitle: downloadButtonLabel,
      isexists: true,
      backcolor: simpleButtonColor,
      navigateclicked: () => beneficiaryNavigationClicked(beneficiaryForms?.btnNavigateTo, beneficiaryFilename)
    }
  ];

  const beneficiaryESignDownloadInputData = [
    {
      messagetype: beneficiaryESign?.messageType,
      message: beneficiaryESign?.message,
      title: beneficiaryESign?.name,
      buttontitle: downloadButtonLabel,
      isexists: true,
      backcolor: simpleButtonColor,
      navigateclicked: () => beneficiaryNavigationClicked(beneficiaryESign?.btnNavigateTo, beneficiaryESignFilename)
    }
  ];

  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];

  const beneficiaryAllModalPopup = {
    showModal: beneficiariesAllModal?.viewDeclaration,
    clickHandler: handleClose,
    dialogClass: "ml-addnewdependent coveragewaiverpopup",
    head: {
      modalTitle: helpContent?.contentHeader
    },
    body: {
      messageText: helpContent?.content
    }
  };

  return (
    <>
      <div className="beneficiariesall baneficiarylist clearfix">
        <div className={`d-flex titlewithicon mb-md-3`}>
          <ButtonComponent btndata={btnBack} />
        </div>
        <div className="clearfix">{isBeneficiaryFormVisible && beneficiaryForms && <ConfirmedItemComponent finishEnrollmentData={downloadInputData} />}</div>
        {/* E-Signature Start */}
        <div className="clearfix">{isEsignable && beneficiaryESign && <ConfirmedItemComponent finishEnrollmentData={beneficiaryESignDownloadInputData} />}</div>
        {/* E-Signature End */}
        <div className="beneficiarylist clearfix mb-md-4">
          {noBeneficiaryBenefits && <AlertComponent propmessageType={`dark`} propmessage={noBeneficiaryBenefits} />}
          {noCoverage && <AlertComponent propmessageType={`secondary`} propmessage={noCoverage} />}
          {!noCoverage && !noBeneficiaryBenefits && beneficiaryList && <Accordion defaultActiveKey="0">{beneficiaryList}</Accordion>}
          {beneficiaryMessage && <AlertComponent propmessageType={`info m-0 mb-md-4`} propmessage={beneficiaryMessage} />}
        </div>
      </div>
      <ModalComponent modalObject={beneficiaryAllModalPopup} />
    </>
  );
};

export default BeneficiariesAll;
