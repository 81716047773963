import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPageSetting } from "../../actions";
import { AlertComponent, BeneficiariesListComponent, ButtonComponent } from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

export const BeneficiariesReallocate = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const beneficiariesAll = useSelector((state: RootState) => state.beneficiary.beneficiariesMultiBenefit || state.beneficiary.beneficiariesAll); //Need to review
  const multiBenefitErrorMessage = useSelector((state: RootState) => state.beneficiary.multiBenefitErrorMessage);
  const { pageSetting } = useSelector((state: RootState) => state.event);
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  const nextButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "btnNext");
  const { beneficiaryMessage, noBeneficiaryBenefits, noCoverage }: any = beneficiariesAll || {};

  /* useEffect(() => {
    dispatch(getBeneficiariesAll());
  }, [dispatch]); */

  const previousPage = () => {
    setPageSetting(pageSetting?.name);
    if (typeof pageSetting?.link === "string") navigate(`/${pageSetting.link}`);
    else navigate("/Benefits");
  };

  const handleBack = () => {
    if (!isTotalValid()) {
      if (pageSetting.name === "BENEFICIARIES") {
        const url = `/${pageSetting?.link}` || "/Beneficiary/All";
        navigate(url);
      } else previousPage();
    }
  };

  const isTotalValid = () => {
    const errorMessage = multiBenefitErrorMessage?.errorMessage?.length > 0 && beneficiariesAll.beneficiaryBenefitsInfo.length > 0 ? true : false;
    return errorMessage;
  };

  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];

  return (
    <>
      <div className="beneficiariesall baneficiarylist clearfix">
        <div className={`d-flex titlewithicon mb-md-3`}>
          <ButtonComponent btndata={btnBack} />
        </div>
        <div className="beneficiarylist clearfix mb-md-4">
          {noBeneficiaryBenefits && <AlertComponent propmessageType={`dark`} propmessage={noBeneficiaryBenefits} />}
          {noCoverage && <AlertComponent propmessageType={`secondary`} propmessage={noCoverage} />}
          {!noCoverage && !noBeneficiaryBenefits && beneficiariesAll && (
            <Accordion defaultActiveKey="0">
              <BeneficiariesListComponent operationID={2} beneficiariesAll={beneficiariesAll} />
            </Accordion>
          )}
        </div>
        {beneficiaryMessage && <AlertComponent propmessageType={`info m-0 mb-md-4`} propmessage={beneficiaryMessage} />}
        <div className="d-flex justify-content-between py-3 btnsfoot">
          <ButtonComponent
            btndata={[
              {
                variant: "outline-secondary btn-save float-start",
                disabled: isTotalValid(),
                display: nextButtonLabel?.display,
                onSubmit: handleBack
              }
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default BeneficiariesReallocate;
