import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBeneficiaries, updateBeneficiary, deleteBeneficiary, showError, updateIndividualBeneficiary, setPageSetting, reallocateMultiBenefit, removeFlexBeneficiaryBenefitID } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { ObjectUtils } from "../../utils";
import { ButtonComponent, AddNewBeneficiaryComponent, TableComponent, TextFieldComponent, ChoiceListComponent } from "../../components";
import { ModalComponent } from "../../components/Modal";
import Card from "react-bootstrap/Card";
import { BeneficiariesValidation, BeneficiariesModal } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import _ from "underscore";

export const Beneficiaries = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { beneficiariesMultiBenefit } = useSelector((state: RootState) => state.beneficiary);
  console.log("beneficiariesMultiBenefit: ", beneficiariesMultiBenefit);

  const benefitIdList = beneficiariesMultiBenefit?.result?.toString() || "-1";
  const beneficiariesData = useSelector((state: RootState) => state.beneficiary.beneficiaries);
  const flexbenefitID = useSelector((state: RootState) => state.beneficiary.flexBeneficiaryBenefitID || -1);
  const benefitID = props?.benefitID && props.benefitID !== -1 ? props?.benefitID : flexbenefitID;
  const [showNewBeneficiaryModal, setShowNewBeneficiaryModal] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [beneficiariesInfo, setBeneficiariesInfo] = useState<any>();
  const [beneficiariesList, setbeneficiariesList] = useState<any>();
  const [beneficiary, setBeneficiary] = useState<any>();
  const [contBeneficiaryList, setcontBeneficiaryList] = useState<any>();
  const [openedBeneficiaryId, setOpenedBeneficiaryId] = useState<string>("");
  const [editBeneficiary, setEditBeneficiary] = useState<boolean>(false);
  const [isTotalValid, setTotalValid] = useState<boolean>(false);
  const { pageSetting, basePageTitle } = useSelector((state: RootState) => state.event);

  const [beneficiaryModal, setBeneficiaryModal] = useState<BeneficiariesModal>({
    showRemoveConfirmModal: false,
    showViewBeneficiary: false,
    setRevocable: false,
    isRevocable: 1,
    showEditModal: false,
    showFullModifyConfirmModal: false,
    showRevocableMsg: false,
    revocableMessage: "",
    isEdited: false,
    showSaveModal: false
  });

  const [beneficiariesError, setBeneficiariesValidation] = useState<BeneficiariesValidation>({
    formErrors: null,
    percentageValid: true,
    trusteeValid: true,
    validationforPercentage: null,
    validationforTrustee: null,
    beneficiaryId: -1,
    isbeneficiaryType: false,
    beneficiariesListError: []
  });
  openedBeneficiaryId;

  const getTotalPer = useCallback((beneficiary: any) => {
    let newTotal: any = 0;
    beneficiary &&
      beneficiary?.map((item: any) => {
        newTotal = item?.percentage === "" ? 0 : item.percentage + parseInt(newTotal);
      });
    return isNaN(newTotal) || newTotal <= 0 ? 0 : newTotal;
  }, []);

  const isTotalPerValid = useCallback(
    (_beneficiaryList = null) => {
      const perValues = (_beneficiaryList || beneficiariesList || {})?.length > 0 ? beneficiariesList : beneficiariesInfo?.beneficiaries;
      const perValuesCont = (_beneficiaryList || contBeneficiaryList || {})?.length > 0 ? beneficiariesList : beneficiariesInfo?.contingentBeneficiaries;
      const totalValue = getTotalPer(perValues);
      const totalValueCont = getTotalPer(perValuesCont);

      setTotalValid(totalValue && totalValueCont !== null ? true : false);
    },
    [beneficiariesInfo?.beneficiaries, beneficiariesInfo?.contingentBeneficiaries, beneficiariesList, contBeneficiaryList, getTotalPer]
  );

  useEffect(() => {
    setBeneficiariesValidation({
      formErrors: null,
      percentageValid: true,
      trusteeValid: true,
      validationforPercentage: "^100$|^[0-9]{1,2}$|^[0-9]{1,2},[0-9]{1,3}$",
      validationforTrustee: "^[ÀÁÂÃÄÅÆçÈÉÊËÌÍÎÏñÒÓÔÕÖÙÚÛÜÝŸàáâãäåæçèéêëìíîïñòóôõöùúûüýÿA-Za-z \\-'\\`]{0,50}$",
      beneficiaryId: -1,
      isbeneficiaryType: false,
      beneficiariesListError: []
    });
  }, [dispatch, beneficiariesInfo]);

  const getBeneficiariesData = useCallback(() => {
    const _beneficiariesAll = props?.beneficiariesAll || false;
    dispatch(
      getBeneficiaries(
        benefitID,
        _beneficiariesAll,
        (response) => {
          setBeneficiariesInfo(response);
          setbeneficiariesList(response?.beneficiaries || []);
          setcontBeneficiaryList(response?.contingentBeneficiaries || []);
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
  }, [benefitID, dispatch, props?.beneficiariesAll]);

  useEffect(() => {
    setErrorList([]);
    //This gets app config data and save into redux store
    if (beneficiariesData) {
      setBeneficiariesInfo(beneficiariesData);
      setbeneficiariesList(beneficiariesData?.beneficiaries || []);
      setcontBeneficiaryList(beneficiariesData?.contingentBeneficiaries || []);
    }
    isTotalPerValid();
  }, [beneficiariesData, isTotalPerValid]);

  useEffect(() => {
    getBeneficiariesData();
  }, [getBeneficiariesData]);

  const getBenefitTitle = () => {
    let benefitTitle: any = "";
    if (props?.benefitTitle) {
      benefitTitle = props?.benefitTitle;
    } else if (!props?.benefitTitle && basePageTitle) {
      benefitTitle = basePageTitle;
    }
    return benefitTitle;
  };

  const handleCloseSaveModal = () => {
    if (!props.hideEdit) previousPage();
    else {
      props.handleCancelEdit();
      setBeneficiaryModal({
        ...beneficiaryModal,
        showSaveModal: false
      });
      removeFlexBeneficiaryBenefitID();
    }
  };

  const getReallocateMultiBenefit = () => {
    console.log("benefitIdList: ", benefitIdList);
    if (benefitIdList !== "-1") {
      dispatch(reallocateMultiBenefit(pageSetting?.name === "BENEFICIARIES" ? -1 : props?.benefitID));
    }
  };

  const contents = beneficiariesInfo?.contentResource?.contents || [];
  const benefitBeneficiaries = beneficiariesInfo?.contentResource?.controlResource?.BenefitBeneficiaries || [];
  const lblNoCoverage = ObjectUtils.extractLabelFromObject(contents, "lblNoCoverage");
  const lblAddBeneficiaryTitle = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblAddBeneficiaryTitle");
  const lblEditBeneficiaryTitle = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblEditBeneficiaryTitle");
  const closeButtonText = ObjectUtils.extractLabelFromObject(contents, "btnClose");
  const lblViewBeneficiaries = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblViewBeneficiaries");
  const lblNoBeneficiaries = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblNoBeneficiaries");
  /* Beneficiaries new design */
  const thcName = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "thcName");
  const thcRelationship = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "thcRelationship");
  const thcTrustee = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "thcTrustee");
  const thcPercent = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "thcPercent");
  const thcTotal = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "ltrTotal");
  const totalValueCustomClass = parseInt(getTotalPer((beneficiariesList || {}).length > 0 ? beneficiariesList : beneficiariesInfo?.beneficiaries)) !== 100 ? "ml-error" : "";
  const totalValueContingentClass = parseInt(getTotalPer((contBeneficiaryList || {}).length > 0 ? contBeneficiaryList : beneficiariesInfo?.contBeneficiaryList)) !== 100 ? "ml-error" : "";
  /* Beneficiaries new design  End*/
  const lblOnFileESign = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblOnFileESign");
  const beneficiaryESignature = beneficiariesInfo?.beneficiaryESignature || null;
  const lblOnFile = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblOnFile");
  const lblPendingNote = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblPendingNote");
  /* Contingent Beneficiaries */
  const lblContingentOnFileESign = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblContingentOnFileESign");
  const lblContingentOnFile = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblContingentOnFile");
  /* Contingent Beneficiaries End */

  /* E-Signature Start */
  //  const lblOnFileESign = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, 'lblOnFileESign')
  // const beneficiaryESignature =this.props.beneficiaries.beneficiaryESignature || null
  //  const lblContingentOnFileESign = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, 'lblContingentOnFileESign')
  const lblPendingESignatureNote = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblPendingESignatureNote");
  /* E-Signature End */

  const lblInstructionsView = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblInstructionsEdit");
  const confirmSaveMessage = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "confirmSaveMessage");
  const lblRelationship = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblRelationship");
  const lblTrustee = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblTrustee");
  const lblSetIrrevocable = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblSetIrrevocable");
  const lblSetRevocable = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblSetRevocable");
  const onFileBeneficiaryInfo = beneficiariesInfo?.onFileBeneficiaryInfo || [];
  const onFileContingentBeneficiaryInfo = beneficiariesInfo?.onFileContingentBeneficiaryInfo || [];
  const benefitTitle = getBenefitTitle();
  const lblViewBeneficiaryTitle = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, beneficiariesInfo?.showPendingBeneficiaryInfo ? "lblPending" : "lblBeneficiaries");

  const lblViewBeneficiaryModalTitle = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblViewBeneficiaryTitle");
  const rvPercentErrorMessage = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "rvPercentErrorMessage");
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const btnSave = ObjectUtils.extractLabelFromObject(footerContent, "btnSave");
  const editIcon = ObjectUtils.extractLabelFromObject(footerContent, "EditIcon");
  const deleteIcon = ObjectUtils.extractLabelFromObject(footerContent, "DeleteIcon");
  const lblCancel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  const yesButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "lblYes");
  const lblContingentPending = ObjectUtils.extractLabelFromObject(benefitBeneficiaries, "lblContingentPending");
  const lblDeleteModifyBeneficiaryHeader = ObjectUtils.extractLabelFromObject(contents, "btnConfirm");
  const lblDeleteModifyBeneficiaryConfirmBtn = ObjectUtils.extractLabelFromObject(contents, "btnOK");
  const lblDeleteModifyBeneficiaryCancelBtn = ObjectUtils.extractLabelFromObject(contents, "btnCancel");

  document.title = lblViewBeneficiaries.display;

  const handleAddNewDependentModel = () => {
    setShowNewBeneficiaryModal(true);
    setEditBeneficiary(false);
  };

  const editBeneficiaryMode = () => {
    setShowNewBeneficiaryModal(true);
    setEditBeneficiary(true);
    setBeneficiaryModal({
      ...beneficiaryModal,
      showFullModifyConfirmModal: false
    });
  };

  const showDeleteConfirmModal = (selectedBeneficiary: any) => {
    setBeneficiaryModal({
      ...beneficiaryModal,
      showRemoveConfirmModal: true
    });
    const updatedBeneficiary: any = { ...selectedBeneficiary };
    updatedBeneficiary.type = getBeneficiaryType(selectedBeneficiary.type);
    setBeneficiary(updatedBeneficiary);
  };

  const showModifyConfirmModal = (selectedBeneficiary: any) => {
    setBeneficiaryModal({
      ...beneficiaryModal,
      showFullModifyConfirmModal: true
    });
    // selectedBeneficiary
    const updatedBeneficiary: any = { ...selectedBeneficiary };
    updatedBeneficiary.type = getBeneficiaryType(selectedBeneficiary.type);
    setBeneficiary(updatedBeneficiary);
  };

  const deleteBeneficiaryClick = () => {
    dispatch(
      deleteBeneficiary(
        beneficiary?.beneficiaryId,
        benefitID,
        (response) => {
          if (!response.hasOwnProperty("reallocateActionLink") || response.reallocateActionLink === null) {
            getBeneficiariesData();
          } else {
            const url = response?.reallocateActionLink.text ? response.reallocateActionLink.text : "/BeneficiaryReallocate";
            navigate(url);
          }
        },
        (errorMessage) => {
          showError(errorMessage);
        }
      )
    );
    closeBeneficiaryModal();
  };

  const onOpenBeneificiary = (benId) => {
    setOpenedBeneficiaryId(benId);
  };

  const closeAddNewDependentModel = () => {
    setShowNewBeneficiaryModal(false);
  };
  const closeBeneficiaryModal = () => {
    setBeneficiaryModal({
      showRemoveConfirmModal: false,
      showViewBeneficiary: false,
      isRevocable: 1,
      setRevocable: false,
      showEditModal: false,
      showFullModifyConfirmModal: false,
      showRevocableMsg: false,
      revocableMessage: "",
      isEdited: false,
      showSaveModal: false
    });
  };

  const modalNewBeneficiary = {
    showModal: showNewBeneficiaryModal,
    clickHandler: closeAddNewDependentModel,
    dialogClass: "ml-addnewbeneficiary",
    head: {
      modalTitle: editBeneficiary ? lblEditBeneficiaryTitle.display : lblAddBeneficiaryTitle.display,
      texttoRead: editIcon.textToRead
    },
    body: {
      bodyClass: "scroll-container container"
    }
  };

  const beneficiaryModalPopup = {
    showModal: beneficiaryModal?.showRemoveConfirmModal || beneficiaryModal?.showRevocableMsg || beneficiaryModal?.showSaveModal || beneficiaryModal?.showFullModifyConfirmModal,
    clickHandler: closeBeneficiaryModal,
    head: {
      modalTitle:
        (beneficiaryModal?.showRemoveConfirmModal && lblDeleteModifyBeneficiaryHeader.display) ||
        (beneficiaryModal?.showSaveModal && lblViewBeneficiaries.display) ||
        (beneficiaryModal?.showFullModifyConfirmModal && lblDeleteModifyBeneficiaryHeader.display) ||
        (beneficiaryModal?.showRevocableMsg && "\u00A0")
    },
    body: {
      bodyClass: "popupmessage",
      confirmBoxData: {
        messagetext:
          (beneficiaryModal?.showFullModifyConfirmModal && beneficiary?.modifyButtonMsg) ||
          (beneficiaryModal?.showRemoveConfirmModal && beneficiary?.removeButtonMsg) ||
          (beneficiaryModal?.showRevocableMsg && beneficiaryModal?.revocableMessage) ||
          (beneficiaryModal?.showSaveModal && confirmSaveMessage.display) ||
          "",
        messagetextclass: beneficiaryModal?.showRevocableMsg ? "" : `ml-popupmessagetext`
      }
    },
    footer:
      beneficiaryModal?.showRemoveConfirmModal || beneficiaryModal?.showFullModifyConfirmModal
        ? {
            footerData: [
              {
                variant: "outline-secondary btn-cancel",
                display: lblDeleteModifyBeneficiaryCancelBtn?.display,
                onSubmit: closeBeneficiaryModal
              },
              beneficiaryModal?.showRemoveConfirmModal && {
                variant: "outline-secondary",
                display: lblDeleteModifyBeneficiaryConfirmBtn?.display,
                onSubmit: deleteBeneficiaryClick
              },
              beneficiaryModal?.showFullModifyConfirmModal && {
                variant: "outline-secondary",
                display: lblDeleteModifyBeneficiaryConfirmBtn?.display,
                onSubmit: editBeneficiaryMode
              }
            ]
          }
        : beneficiaryModal?.showSaveModal && {
            footerData: [
              {
                variant: "outline-secondary btn-cancel",
                display: lblCancel?.display,
                onSubmit: closeBeneficiaryModal
              },
              {
                variant: "outline-secondary",
                display: yesButtonLabel?.display,
                onSubmit: handleCloseSaveModal
              }
            ]
          }
  };

  const showViewBeneficiaryModalPopup = {
    showModal: beneficiaryModal?.showViewBeneficiary,
    clickHandler: closeBeneficiaryModal,
    head: {
      modalTitle: beneficiaryModal?.showViewBeneficiary && lblViewBeneficiaryModalTitle.display
    }
  };

  const validateField = (fieldName: string, value: any, beneficiaryId: any, _beneficiaryType: boolean) => {
    const fieldValidationErrors: any = {};
    let _validationforPercentage: boolean = beneficiariesError?.percentageValid;
    let _validationforTrustee: boolean = beneficiariesError?.trusteeValid;
    switch (fieldName) {
      case "percentage":
        _validationforPercentage = new RegExp(beneficiariesError?.validationforPercentage).test(value);
        fieldValidationErrors.percentage = _validationforPercentage ? "" : " is invalid";
        break;
      case "trustee":
        _validationforTrustee = new RegExp(beneficiariesError?.validationforTrustee).test(value);
        fieldValidationErrors.trustee = _validationforTrustee ? "" : " is invalid";
        break;

      default:
        break;
    }

    const _beneficiariesListError: any = [...beneficiariesError?.beneficiariesListError];
    const index: any = _beneficiariesListError.findIndex((item) => item.beneficiaryId === beneficiaryId && item.isContBeneficiary == _beneficiaryType);
    if (index > -1) {
      _beneficiariesListError.forEach((opt: any) => {
        if (opt.beneficiaryId === beneficiaryId && opt.isContBeneficiary === _beneficiaryType) (opt.percentageValid = _validationforPercentage), (opt.isContBeneficiary = _beneficiaryType);
      });
    }
    !_validationforPercentage && index < 0 && _beneficiariesListError.push({ beneficiaryId: beneficiaryId, percentageValid: _validationforPercentage, isContBeneficiary: _beneficiaryType });

    setBeneficiariesValidation({
      ...beneficiariesError,
      formErrors: fieldValidationErrors,
      percentageValid: _validationforPercentage,
      trusteeValid: _validationforTrustee,
      beneficiaryId: beneficiaryId,
      isbeneficiaryType: _beneficiaryType,
      beneficiariesListError: _beneficiariesListError
    });
  };

  const setPercentage = (isContBeneficiary: boolean, beneficiary: any, callback: any) => {
    const name: any = Object.keys(callback)[0].split("_")[0];
    const value: any = Object.values(callback)[0];
    validateField(name, value, beneficiary?.beneficiaryId, isContBeneficiary);
    const _beneficiaryList: any = isContBeneficiary ? [...contBeneficiaryList] : [...beneficiariesList];
    _beneficiaryList.forEach((obj) => {
      if (obj.beneficiaryId === beneficiary?.beneficiaryId) obj.percentage = value && parseInt(value);
    });
    !isContBeneficiary && setbeneficiariesList(_beneficiaryList);
    isContBeneficiary && setcontBeneficiaryList(_beneficiaryList);
    isTotalPerValid(_beneficiaryList);
      //Handle package edit
      if (props.handleEdit) 
        props.handleEdit(benefitID, true)
  };

  const getBeneficiaryType = (type: string) => {
    const index = _.indexOf(beneficiariesInfo.typeList, type);
    let benefiaryType = "";
    switch (index) {
      case 0:
        benefiaryType = "Person";
        break;
      case 1:
        benefiaryType = "Charity";
        break;
      case 2:
        benefiaryType = "Estate";
        break;
      default:
        benefiaryType = "Unknown";
    }

    return benefiaryType;
  };

  const toggleDescription = (_revocableMessage: string) => {
    setBeneficiaryModal({
      ...beneficiaryModal,
      showRevocableMsg: true,
      revocableMessage: _revocableMessage
    });
  };

  const showViewBeneficiaryModal = (selectedBeneficiary: any) => {
    setBeneficiaryModal({
      ...beneficiaryModal,
      showViewBeneficiary: true,
      setRevocable: false,
      isRevocable: parseInt(selectedBeneficiary?.isRevocable),
      showEditModal: true
    });
    const updatedBeneficiary: any = { ...selectedBeneficiary };
    updatedBeneficiary.type = getBeneficiaryType(selectedBeneficiary.type);
    setBeneficiary(updatedBeneficiary);
  };

  const previousPage = useCallback(() => {
    pageSetting && pageSetting?.name && setPageSetting(pageSetting?.name);
    if (typeof pageSetting.link === "string") {
      navigate(`/${pageSetting.link}`);
    } else {
      navigate("/Benefits");
    }
  }, [navigate, pageSetting]);

  const updateBeneficiaryData = useCallback(
    (event: any, onSaveAndSign: boolean, actionLink: any) => {
      dispatch(
        updateBeneficiary(
          beneficiariesList,
          contBeneficiaryList,
          benefitID,
          () => {
            if (props.isCancelVisible) {
              getBeneficiariesData();
              getReallocateMultiBenefit();
              onSaveAndSign && navigate(`/${actionLink}`);
            } else previousPage();
            console.log(event);
            props.hideEdit && props.hideEdit(benefitID);
          },
          (error) => {
            setErrorList(error);
          }
        )
      );
    },
    [beneficiariesList, benefitID, contBeneficiaryList, dispatch, getBeneficiariesData, navigate, previousPage, props]
  );

  const onSave = (event: any) => {
    setErrorList([]);
    updateBeneficiaryData(event, false, "");
  };

  /* E-Signature Start */
  const onSaveAndSign = (event, actionLink) => {
    setErrorList([]);
    updateBeneficiaryData(event, true, actionLink);
  };
  /* E-Signature End */
  const setValue = (callback: any) => {
    const name: any = Object.keys(callback)[0].split("_")[0];
    const value: any = Object.values(callback)[0];
    validateField(name, value, beneficiary?.beneficiaryId, false);
    const newBeneficiary = { ...beneficiary };
    if (name === "revocable") {
      delete newBeneficiary.isRevocable;
      newBeneficiary[name] = parseInt(value);
      setBeneficiaryModal({
        ...beneficiaryModal,
        setRevocable: !beneficiaryModal.setRevocable
      });
    } else {
      newBeneficiary[name] = value;
    }
    setBeneficiary(newBeneficiary);
  };

  const changeRevocability = (callback: any) => {
    const name: any = Object.keys(callback)[0].split("_")[0];
    const newBeneficiary = { ...beneficiary };
    if (newBeneficiary[name] === 0) newBeneficiary[name] = 1;
    else newBeneficiary[name] = 0;
    setBeneficiaryModal({
      ...beneficiaryModal,
      setRevocable: !beneficiaryModal?.setRevocable
    });
    setBeneficiary(newBeneficiary);
  };
  const onSaveIndividualBeneficiary = () => {
    dispatch(
      updateIndividualBeneficiary(beneficiary, () => {
        getBeneficiariesData();
      })
    );
    closeBeneficiaryModal();
  };
  const dob = ObjectUtils.extractLabelFromObject(contents, "DOB");
  const toolTipIcon = ObjectUtils.extractLabelFromObject(contents, "ToolTipIcon");
  const lblSetIrrevocableOptions = [{ text: lblSetIrrevocable.display, value: 0 }];
  const lblSetRevocableOptions = [{ text: lblSetRevocable.display, value: 1 }];
  const renderBeneficiarieList = (isContBeneficiary, beneficiaryList: any, isApproved: boolean, customClass: string) => {
    console.log("beneficiariesError", customClass);
    //Table Object
    const headerData: any = [{ headername: thcName.display }, { headername: thcRelationship.display }, { headername: thcTrustee.display }, { headername: thcPercent.display }];
    const gridRowBodyObject: any = [];
    beneficiaryList?.map((item: any, index: string) => {
      const { isRevocable, isEditable, isReadOnly, modifyEnabled, fullModify, removeEnabled } = item;
      const birthDate = item?.birthDate;
      const type = item.type ? getBeneficiaryType(item.type) : "Person";
      const isTrustee = item?.trustee !== null && item?.trustee !== "" && item?.trustee !== "N/A" ? true : false;
      const beneficiaryName = isApproved ? item.beneficiaryName : item?.name;
      const gridRowData: any = [
        isApproved && {
          type: "text",
          display: `<strong>${beneficiaryName}</strong><br />${item.dateOfBirth ? item.dateOfBirth : "n/a"}`
        },
        !isApproved && {
          type: "text",
          display: `<strong>${beneficiaryName}</strong><br />
       ${dob?.length > 0 ? dob.display + " " : "D.O.B"}
       ${birthDate && type === "Person" ? birthDate.display : "n/a"}
       `
        },
        !isApproved && {
          type: "text",
          display: item?.relationship && type !== "Estate" ? item?.relationship : "n/a"
        },
        isApproved && {
          type: "text",
          display: item?.relationShip ? item?.relationShip : "n/a"
        },
        !isApproved && {
          type: "text",
          display: (type === "Person" || type === "Estate") && isTrustee ? item.trustee : "n/a",
          colclassname: "text-start"
        },
        isApproved && {
          type: "text",
          display: item?.trustee ? item?.trustee : "n/a"
        },
        !isApproved && {
          type: "text",
          mode: "edit",
          colclassname: "justify-content-center align-items-center",
          tgclassName: "percentage",
          fgclass: "beneficiary",
          arialabel: "-",
          // beneficiariesError.beneficiaryType="Contingent"
          textGroup: [
            {
              type: "number",
              controlid: `${isContBeneficiary ? "ContBeneficiary" : "Beneficiary"}-${item?.beneficiaryId}-${index}`,
              name: "percentage",
              value: `${item?.percentage}`,
              isInvalid: beneficiariesError?.isbeneficiaryType === isContBeneficiary && !beneficiariesError?.percentageValid,
              mode: "edit",
              disabled: isReadOnly,
              classname: "",
              colclassname: "text-end",
              handlechange: (event: any) => setPercentage(isContBeneficiary, item, event)
            },
            {
              type: "label",
              display: `<span className="d-flex lbl-percentage">%</span>`
            },
            isReadOnly && {
              type: "tooltipicon",
              ariaLabel: toolTipIcon.textToRead,
              className: "tooltip-icon icon-action",
              onSubmit: () => toggleDescription(item?.revocableMessage)
            },
            (isEditable || type === "Person") &&
              (isRevocable === 1 || removeEnabled) &&
              !fullModify && {
                type: "editicon",
                className: "link icon-action edit-icon mx-2",
                ariaLabel: `${editIcon.textToRead} ${beneficiaryName}`,
                onSubmit: () => showViewBeneficiaryModal(item)
              },
            modifyEnabled &&
              fullModify && {
                type: "editicon",
                className: "link icon-action edit-icon mx-2",
                ariaLabel: `${editIcon.textToRead} ${beneficiaryName}`,
                onSubmit: () => showModifyConfirmModal(item)
              },
            removeEnabled && {
              type: "deleteicon",
              className: "btnaction icon-action edit-icon close-icon float-end",
              ariaLabel: `${deleteIcon.textToRead} ${beneficiaryName}`,
              onSubmit: () => showDeleteConfirmModal(item)
            }
          ]
        },
        isApproved &&
          !item?.removedMsg && {
            type: "text",
            display: item.percentage + "%",
            colclassname: "text-end"
          },
        isApproved &&
          item?.removedMsg && {
            type: "text",
            colclassname: "text-end",
            display: `<span class="ml-mandatory-sign">(${item?.removedMsg})</span> ${item?.percentage}%`
          }
      ];

      const percentErrorMsg = [
        {
          type: "alert",
          colSpan: 4,
          colclassname: "pt-2 alert alert-danger text-center pb-2",
          errormessage: rvPercentErrorMessage.display
        }
      ];
      gridRowBodyObject.push(gridRowData);
      const objSelected = beneficiariesError?.beneficiariesListError?.filter(
        (obj) => obj.beneficiaryId == item?.beneficiaryId && obj.percentageValid == false && obj?.isContBeneficiary == isContBeneficiary
      );

      objSelected?.length > 0 &&
        // && beneficiariesError?.isbeneficiaryType === isContBeneficiary &&
        gridRowBodyObject.push(percentErrorMsg);
    });

    const tableObject = {
      className: "table-beneficiaries w-100",
      header: {
        headerData: headerData
      },
      body: {
        gridRowData: gridRowBodyObject
      },
      footer: {
        gridFooterData: [
          [
            {
              type: "text",
              colSpan: 3,
              colclassname: "text-end no-right-border",
              display: `<strong>${thcTotal.display}</strong>`
            },
            !isContBeneficiary
              ? {
                  type: "text",
                  colclassname: `text-start`,
                  display: `<strong class="m-0 ${totalValueCustomClass}">${getTotalPer((beneficiaryList || []).length > 0 ? beneficiaryList : beneficiariesInfo?.beneficiaries)}%</strong>`
                }
              : {
                  type: "text",
                  colclassname: `text-start`,
                  display: `<strong class="m-0 ${totalValueContingentClass}">${getTotalPer((beneficiaryList || []).length > 0 ? beneficiaryList : beneficiariesInfo?.contBeneficiaryList)}%</strong>`
                }
          ]
        ]
      }
    };
    return tableObject;
  };

  const checkIfCanGoBack = () => {
    getReallocateMultiBenefit();
    if (beneficiaryModal.isEdited)
      setBeneficiaryModal({
        ...beneficiaryModal,
        showSaveModal: true
      });
    else handleCloseSaveModal();
  };

  const error = beneficiariesError?.beneficiariesListError?.filter((obj) => obj.percentageValid == false && obj.isContBeneficiary == false);
  const cardFooterBtns = {
    handleShow: [
      {
        variant: "outline-secondary btn-addnewbeneficiary",
        display: lblAddBeneficiaryTitle?.display,
        onSubmit: handleAddNewDependentModel
      }
    ],
    handleCancel: [
      {
        variant: "outline-secondary btn-cancel",
        display: lblCancel.display,
        onSubmit: checkIfCanGoBack
      }
    ],
    handleSave: [
      {
        variant: "outline-secondary btn-save",
        display: beneficiariesInfo?.isEsignable && beneficiaryESignature ? beneficiaryESignature?.text : btnSave.display,
        disabled: !isTotalValid || error?.length > 0,
        onSubmit: beneficiariesInfo?.isEsignable && beneficiaryESignature ? (event) => onSaveAndSign(event, beneficiaryESignature?.actionlink) : onSave
      }
    ]
  };

  const beneficiaryViewBtnInput = [
    {
      variant: "outline-secondary btn-sm btn-cancel",
      display: lblDeleteModifyBeneficiaryCancelBtn?.display,
      onSubmit: closeBeneficiaryModal
    },
    btnSave.display && {
      variant: "outline-secondary btn-sm btn-ok",
      display: btnSave.display,
      onSubmit: onSaveIndividualBeneficiary
    }
  ];

  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: checkIfCanGoBack
    }
  ];

  return (
    <>
      <div className={`baneficiarylist`}>
        {!props.hideEdit && (
          <div className={`d-flex titlewithicon mb-md-3`}>
            <ButtonComponent btndata={btnBack} />
          </div>
        )}
        <Card className="clearfix pcard">
          <Card.Header>
            <h2 className="heading-white">{benefitTitle}</h2>
          </Card.Header>
          <Card.Body>
            {beneficiariesInfo?.showlblNoCoverage && (
              <span className="d-block" aria-hidden={!beneficiariesInfo?.showlblNoCoverage}>
                {lblNoCoverage.display}
              </span>
            )}
            {beneficiariesList && beneficiariesList?.length === 0 && (
              <div className={`alert alert-primary ml-description mb-4 clearfix`} role="alert">
                <p className={`m-0`} dangerouslySetInnerHTML={{ __html: lblNoBeneficiaries.display }} />
              </div>
            )}
            {/* On File */}
            {onFileBeneficiaryInfo?.length > 0 && (
              <div className="tbl-beneficiaries">
                {/* E-Signature Start */}
                <h3 className="title fw-bold">{beneficiariesInfo?.isEsignable ? lblOnFileESign.display : lblOnFile.display}</h3>
                {/* E-Signature End */}
                <TableComponent tableObject={renderBeneficiarieList(false, onFileBeneficiaryInfo, true, "")} />
              </div>
            )}
            {/* on File Contingent Beneficiary Info */}
            {onFileContingentBeneficiaryInfo?.length > 0 && (
              <div className="tbl-beneficiaries">
                {/* E-Signature Start */}
                <h3 className="title fw-bold">{beneficiariesInfo?.isEsignable ? lblContingentOnFileESign.display : lblContingentOnFile.display}</h3>
                {/* E-Signature End */}
                <TableComponent tableObject={renderBeneficiarieList(true, onFileContingentBeneficiaryInfo, true, "")} />
              </div>
            )}

            {/* Pending */}
            <br />
            {beneficiariesList?.length > 0 && (
              <div className="tbl-beneficiaries">
                {/* E-Signature Start */}
                <h3 className="title fw-bold">{lblViewBeneficiaryTitle.display}</h3>
                {/* E-Signature End */}
                <TableComponent tableObject={renderBeneficiarieList(false, beneficiariesList, false, totalValueCustomClass)} />
              </div>
            )}
            <br />
            {contBeneficiaryList?.length > 0 && (
              <div className="tbl-beneficiaries">
                {/* E-Signature Start */}
                <h3 className="title fw-bold">{lblContingentPending.display}</h3>
                {/* E-Signature End */}
                <TableComponent tableObject={renderBeneficiarieList(true, contBeneficiaryList, false, totalValueCustomClass)} />
              </div>
            )}

            {/* Messages */}
            {errorList?.length > 0 ? (
              errorList?.length === 1 ? (
                <>
                  <p role="alert" className="d-block alert alert-danger mb-2" dangerouslySetInnerHTML={{ __html: errorList[0] }} />
                </>
              ) : (
                errorList?.map((e) => (
                  <>
                    <p role="alert" className="ml-inputfields has-error formErrors formErrorPStyle mb-2" dangerouslySetInnerHTML={{ __html: e }} />
                  </>
                ))
              )
            ) : (
              ""
            )}

            {/* E-Signature Start */}
            {beneficiariesInfo?.isEsignable && beneficiariesInfo?.showPendingBeneficiaryInfo && (
              <span className="d-block mb-2 alert alert-info" role="alert">
                {lblViewBeneficiaryTitle.display} <strong>{lblPendingESignatureNote.display}</strong>
              </span>
            )}
            {/* E-Signature End */}

            {!beneficiariesInfo?.isEsignable && beneficiariesInfo?.showPendingBeneficiaryInfo && (
              <span className="d-block mb-2 alert alert-info" role="alert">
                {lblViewBeneficiaryTitle.display} <strong>{lblPendingNote.display}</strong>
              </span>
            )}

            {beneficiariesInfo?.beneficiariesMessage !== "" && (
              <span className="d-block mb-2 alert alert-info" role="alert" dangerouslySetInnerHTML={{ __html: beneficiariesInfo?.beneficiariesMessage }} />
            )}

            {/* Buttons */}
            {beneficiariesList?.length !== 0 && !props?.isCancelVisible && (
              <div className="d-flex justify-content-between py-3 btnsfoot mt-1">
                <ButtonComponent btndata={cardFooterBtns.handleShow} />
                <ButtonComponent btndata={cardFooterBtns.handleSave} />
              </div>
            )}
            {beneficiariesList?.length !== 0 && props?.isCancelVisible && (
              <>
                <div className="d-flex justify-content-between py-3 btnsfoot">
                  <ButtonComponent btndata={cardFooterBtns.handleShow} />
                  {/* <ButtonComponent btndata={cardFooterBtns.handleSave} /> */}
                </div>

                <div className="d-flex justify-content-between py-3 btnsfoot">
                  <ButtonComponent btndata={cardFooterBtns.handleCancel} />
                  <ButtonComponent btndata={cardFooterBtns.handleSave} />
                </div>
              </>
            )}

            {beneficiariesList?.length === 0 && props?.isCancelVisible && (
              <div className="d-flex justify-content-between py-3 btnsfoot">
                <ButtonComponent btndata={cardFooterBtns.handleShow} />
                <ButtonComponent btndata={cardFooterBtns.handleCancel} />
              </div>
            )}
          </Card.Body>
        </Card>
        {beneficiariesList?.length === 0 && !props?.isCancelVisible && (
          <div className="d-flex justify-content-between py-3 btnsfoot">
            <ButtonComponent btndata={cardFooterBtns.handleShow} />
          </div>
        )}
        <ModalComponent modalObject={modalNewBeneficiary}>
          <AddNewBeneficiaryComponent
            beneficiary={editBeneficiary ? beneficiary : null}
            beneficiaries={beneficiariesInfo?.beneficiaries}
            beneficiariesFull={beneficiariesInfo}
            onOpenBeneificiary={onOpenBeneificiary}
            isEditMode={editBeneficiary}
            benefiaryType={editBeneficiary ? beneficiary?.type.toLowerCase() : "person"}
            closeButtonText={closeButtonText}
            selectedBenefitID={benefitID}
            isBeneficiariesAll={props?.beneficiariesAll}
            closeAddNewDependentModel={closeAddNewDependentModel}
          />
        </ModalComponent>
        <ModalComponent modalObject={beneficiaryModalPopup}></ModalComponent>
        <ModalComponent modalObject={showViewBeneficiaryModalPopup}>
          <>
            <div className="description pb-3">
              <p className="m-0" aria-label={lblInstructionsView.textToRead} dangerouslySetInnerHTML={{ __html: lblInstructionsView.display }}></p>
            </div>

            <div className="beneficiaryinfo py-3">
              <div>{beneficiary?.name}</div>
              {beneficiary?.type === "Person" ? (
                <div className="py-2 d-flex">
                  <span>{dob?.display}</span>
                  <span>{beneficiary?.birthDate && beneficiary?.birthDate?.display}</span>
                </div>
              ) : (
                ""
              )}
              {beneficiary?.type !== "Estate" ? (
                <div className="d-flex">
                  <span>{lblRelationship.display}</span>
                  <span>{beneficiary?.relationship}</span>
                </div>
              ) : (
                ""
              )}
              <div className="pt-2">
                {beneficiary?.type !== "Charity" ? (
                  <span id={"trustee"}>
                    <label htmlFor={"trustee"}>{lblTrustee.display}</label>
                  </span>
                ) : (
                  ""
                )}
                {beneficiary?.isEditable === true && beneficiary?.type === "Person" ? (
                  <div className={`ml-inputfields ml-trustee`}>
                    <TextFieldComponent
                      inputdata={[
                        {
                          type: "text",
                          // display: lblTrustee.display,
                          texttoread: beneficiary?.trustee ? beneficiary?.trustee : lblTrustee?.display,
                          value: beneficiary?.trustee || "",
                          name: "trustee",
                          mode: "edit",
                          colclassname: "col-sm-12 col-md-4",
                          lblclassname: "edit-input-label",
                          handlechange: setValue
                        }
                      ]}
                    />
                  </div>
                ) : beneficiary?.type === "Person" || beneficiary?.type === "Estate" ? (
                  <span>{beneficiary?.trustee}</span>
                ) : (
                  ""
                )}
              </div>
              {beneficiary?.type === "Person" && beneficiariesInfo?.showIsRevocable ? (
                <div className="ml-radiolist clearfix mt-1">
                  <ChoiceListComponent
                    choicelistdata={{
                      type: "checkbox",
                      selected: beneficiaryModal?.setRevocable,
                      name: "revocable",
                      optionsinput: beneficiaryModal?.isRevocable === 1 ? lblSetIrrevocableOptions : lblSetRevocableOptions,
                      width: "50",
                      fgclass: "float-left",
                      wrapperclass: "ml-radiolist",
                      handlechange: beneficiaryModal?.setRevocable ? changeRevocability : setValue
                    }}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={"ml-buttons d-flex justify-content-between"}>
              <ButtonComponent btndata={beneficiaryViewBtnInput} />
            </div>
          </>
        </ModalComponent>
      </div>
    </>
  );
};

export default Beneficiaries;
