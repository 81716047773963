import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSummaryBeneficiaries } from "../../actions";
import { AlertComponent, BeneficiariesListComponent, ButtonComponent } from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

export const SummaryBeneficiaries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const beneficiariesAll = useSelector((state: RootState) => state.beneficiary.summaryBeneficiaries);
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  const { beneficiaryMessage, noBeneficiaryBenefits, noCoverage }: any = beneficiariesAll || {};

  useEffect(() => {
    dispatch(getSummaryBeneficiaries());
  }, [dispatch]);

  const handleBack = () => {
    navigate("/Summary");
  };

  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];

  return (
    <>
      <div className="summary summarybeneficiaries beneficiariesall baneficiarylist clearfix">
        <div className={`d-flex titlewithicon mb-3`}>
          <ButtonComponent btndata={btnBack} />
        </div>
        {noBeneficiaryBenefits && <AlertComponent propmessageType={`dark`} propmessage={noBeneficiaryBenefits} />}
        {noCoverage && <AlertComponent propmessageType={`secondary`} propmessage={noCoverage} />}
        {noCoverage && (
          <div className="alert alert-secondary" role="alert">
            {noCoverage}
          </div>
        )}
        {!noCoverage && !noBeneficiaryBenefits && beneficiariesAll && (
          <Accordion defaultActiveKey="0">
            <BeneficiariesListComponent operationID={3} beneficiariesAll={beneficiariesAll} />
          </Accordion>
        )}

        {beneficiaryMessage && <AlertComponent propmessageType={`info m-0 mb-md-4`} propmessage={beneficiaryMessage} />}
      </div>
    </>
  );
};

export default SummaryBeneficiaries;
