import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMultiBenefits } from "../../actions";
import { ShellComponent } from "../../components";
import { RootState } from "../../reducers";

export const BenefitShell = () => {
  const dispatch = useDispatch();
  const { smokerContent, benefitsLists, benefitUrlList } = useSelector((state: RootState) => state.benefit);
  const benefitList: any = [];

  useEffect(() => {
    async function fetchBenefitInfo() {
      if (!benefitsLists?.length) {
        dispatch(await getMultiBenefits());
      }
    }
    fetchBenefitInfo();
  }, [benefitsLists?.length, dispatch]);

  benefitsLists?.map((benefit: any, index: number) => {
    const benefitURL = benefitUrlList[index] || "";
    const benefitName = benefitURL?.split("/")[0].toLowerCase();
    const benefitID = benefitURL?.split("/")?.pop();
    benefitList.push(
      <div key={`div-${benefitName}-benefit-${index}-url-${benefitURL}-ID`}>
        <ShellComponent benefit={benefit} benefitName={benefitName} smokerContent={smokerContent} benefitURL={benefitURL} benefitID={benefitID} />
      </div>
    );
  });
  return <>{benefitList}</>;
};
export default BenefitShell;
