import { useCallback, useState } from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { setPageSetting } from "../../actions";
import { useNavigate } from "react-router-dom";
import { PackageMultiBeneficiariesModal } from "../../interfaces";
import { ObjectUtils } from "../../utils";
import { Accordion, Card } from "react-bootstrap";
import Beneficiaries from "./Beneficiaries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { AlertComponent, ButtonComponent, ModalComponent } from "../../components";

export const PackageMultiBeneficiaries = () => {
  const navigate = useNavigate();
  const { pageSetting } = useSelector((state: RootState) => state.event);
  
  const { packageMultibeneficiaryBenefits } = useSelector((state: RootState) => state.beneficiary);
  const { benefitsLists } = useSelector((state: RootState) => state.benefit);
  const { beneficiaries } = useSelector((state: RootState) => state.beneficiary);
  const benefitMessageStatusList = {};
  const packageMultiBeneficiaries = (benefitsLists && benefitsLists.length && benefitsLists[0]) || {};
  const beneficiaryBenefits =
    packageMultiBeneficiaries?.beneficiaryBenefits || packageMultibeneficiaryBenefits || [];
  beneficiaryBenefits &&
    beneficiaryBenefits?.map((benefit: any) => {
      benefitMessageStatusList[benefit?.benefitID] = benefit?.showErrorMessage;
    });
  const [packageMultiBeneficiariesModal, setPackageMultiBeneficiariesModal] =
    useState<PackageMultiBeneficiariesModal>({
      expandedID:
        beneficiaryBenefits && beneficiaryBenefits.length > 0 ? beneficiaryBenefits[0].benefitID : -1,
      expandedAccordion: beneficiaryBenefits && beneficiaryBenefits.length > 0 ? 0 : -1,
      benefitMessageStatus: benefitMessageStatusList,
      isEdited: false,
      showSaveModal: false,
    });

  const previousPage = useCallback(() => {
    pageSetting && pageSetting?.name && setPageSetting(pageSetting?.name);
    if (typeof pageSetting.link === "string") {
      navigate(`/${pageSetting.link}`);
    } else {
      navigate("/Benefits");
    }
  }, [navigate, pageSetting]);

  const handleSelect = (benefitID: any) => {
    console.log(benefitID);
    setPackageMultiBeneficiariesModal({
      ...packageMultiBeneficiariesModal,
      expandedID: benefitID,
    });
  };

  const handleCancel = (benefitID: any) => {
    const benefitMessageStatus = packageMultiBeneficiariesModal.benefitMessageStatus
    if (beneficiaryBenefits.length > 1 && (!benefitID || !benefitMessageStatus[benefitID])) {
      setPackageMultiBeneficiariesModal({
        ...packageMultiBeneficiariesModal,
        expandedID: -1,
        expandedAccordion: -1,
      });
    } else {
      if (benefitMessageStatus[benefitID]) {
        benefitMessageStatus[benefitID] = false;
        setPackageMultiBeneficiariesModal({
          ...packageMultiBeneficiariesModal,
          benefitMessageStatus: benefitMessageStatus,
          expandedID: -1,
          expandedAccordion: -1,
        });
      }
    }
  };

  const toggleAccordion = (openElementIndex: number) => {
    setPackageMultiBeneficiariesModal({
      ...packageMultiBeneficiariesModal,
      expandedAccordion:
        openElementIndex === packageMultiBeneficiariesModal.expandedAccordion ? -1 : openElementIndex,
      expandedID: beneficiaryBenefits[openElementIndex].benefitID,
    });
  };

  const handlePackageEdit = (beneftID: any, isEditing: any) => {
    if (packageMultiBeneficiariesModal.expandedID === beneftID)
      setPackageMultiBeneficiariesModal({
        ...packageMultiBeneficiariesModal,
        isEdited: isEditing,
      });
  };

  const checkIfCanGoBack = () => {
    if (packageMultiBeneficiariesModal.isEdited)
      setPackageMultiBeneficiariesModal({
        ...packageMultiBeneficiariesModal,
        showSaveModal: true,
      });
    else previousPage();
  };

  const handleCancelSaveModal = () => {
    setPackageMultiBeneficiariesModal({
      ...packageMultiBeneficiariesModal,
      showSaveModal: false,
    });
  };

  const handleCloseSaveModal = () => {
    previousPage();
    handleCancelSaveModal();
  };

  const beneficiaryList: any = [];
  const contents = (packageMultiBeneficiaries && packageMultiBeneficiaries?.contentResource?.contents) || {};
  const benefitBeneficiaryContent =
    beneficiaries?.contentResource?.controlResource?.BenefitBeneficiaries || [];
  const isCancelVisible = beneficiaryBenefits?.length > 1 ? true : false;
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const pageTitle = ObjectUtils.extractLabelFromObject(contents, "lblViewBeneficiaries");
  const expandCollapseIcon = ObjectUtils.extractLabelFromObject(footerContent, "ExpandCollapseIcon");
  const lblBeneficiariesRequired = ObjectUtils.extractLabelFromObject(contents, "lblBeneficiariesRequired");
  const lblChooseBeneficiaries = ObjectUtils.extractLabelFromObject(footerContent, "lblViewBeneficiaries");
  const confirmSaveMessage = ObjectUtils.extractLabelFromObject(
    benefitBeneficiaryContent || contents,
    "confirmSaveMessage",
  );
  const noButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "btnCancel");
  const yesButtonLabel = ObjectUtils.extractLabelFromObject(footerContent, "lblYes");
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");

  beneficiaryBenefits &&
    beneficiaryBenefits?.map((benefit: any, index: any) => {
      beneficiaryList.push(
        <>
          <div key={`div-${benefit.benefitID}-ID-${benefit.benefitName}-package`} className="mb-4">
            {packageMultiBeneficiariesModal.expandedID !== benefit?.benefitID && (
              <>
                <Card className="pcard">
                  <Accordion
                    as={Card.Header}
                    eventKey={`${index}`}
                    onClick={() => {
                      toggleAccordion(index);
                    }}
                  >
                    <FontAwesomeIcon
                      aria-label={expandCollapseIcon.textToRead}
                      className="pr-1"
                      icon={
                        packageMultiBeneficiariesModal.expandedAccordion === index
                          ? faChevronUp
                          : faChevronDown
                      }
                    />{" "}
                    {benefit.benefitName}
                  </Accordion>
                  <Card.Body>
                    {packageMultiBeneficiariesModal.benefitMessageStatus[benefit.benefitID] && (
                      <AlertComponent
                        propmessageType={`danger mb-2`}
                        propmessage={lblBeneficiariesRequired.display}
                      />
                    )}

                    <ButtonComponent
                      btndata={[
                        {
                          variant: "outline-secondary",
                          display: lblChooseBeneficiaries.display,
                          onClick: () => handleSelect(benefit.benefitID),
                        },
                      ]}
                    />
                  </Card.Body>
                </Card>
              </>
            )}
            {packageMultiBeneficiariesModal.expandedID === benefit.benefitID && (
              <>
                <div className="clearfix">
                  <Beneficiaries
                    benefitID={benefit.benefitID}
                    benefitTitle={benefit.benefitName}
                    isCancelVisible={isCancelVisible}
                    handleCancelEdit={handleCancel}
                    hideEdit={handleCancel}
                    handleEdit={handlePackageEdit}
                  />
                </div>
              </>
            )}
          </div>
        </>,
      );
    });

  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: checkIfCanGoBack,
    },
  ];

  const confirmSaveMessageModal = {
    showModal: packageMultiBeneficiariesModal.showSaveModal,
    clickHandler: handleCancelSaveModal,
    head: {
      modalTitle: pageTitle.display,
    },
    body: {
      confirmBoxData: {
        messagetext: confirmSaveMessage.display,
        messagetextclass: "ml-popupmessagetext align-left",
      },
    },
    footer: {
      footerData: [
        {
          variant: "outline-secondary btn-cancel",
          display: noButtonLabel?.display,
          onSubmit: handleCancelSaveModal,
        },
        {
          variant: "outline-secondary",
          display: yesButtonLabel?.display,
          onSubmit: handleCloseSaveModal,
        },
      ],
    },
  };

  return (
    <div className="packagebeneficiaries clearfix">
      <div className={`d-flex titlewithicon mb-3`}>
        <ButtonComponent btndata={btnBack} />
      </div>
      <Accordion defaultActiveKey="0" className="baneficiarylist">
        {beneficiaryList}
      </Accordion>
      <ModalComponent modalObject={confirmSaveMessageModal} />
    </div>
  );
};