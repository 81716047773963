import { ObjectUtils } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getSummaryBeneficiariesESignature, summarySaveESignature } from "../../actions";
import { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { Card, Form, Container, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertComponent, ButtonComponent } from "../../components";
import { BeneficiariesESignatureModal } from "../../interfaces/formValidation";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { navigateChangedState } from "../../constants";

export const SummaryESignBeneficiaries = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [beneficiariesESignatureModal, setBeneficiariesESignatureModal] = useState<BeneficiariesESignatureModal>({
    isSelected: false,
    verificationText: "",
    isAgreed: false,
    verifyTypeToSign: ""
  });
  const summaryBeneficiariesData = useSelector((state: RootState) => state.beneficiary.summaryBeneficiaries);
  const handleValue = () => {
    setBeneficiariesESignatureModal({
      ...beneficiariesESignatureModal,
      isSelected: !beneficiariesESignatureModal?.isSelected
    });
  };

  useEffect(() => {
    const { pathname, search } = location;
    const actionLink = pathname + search;
    dispatch(getSummaryBeneficiariesESignature(actionLink));
  }, [dispatch, location]);

  const onSave = () => {
    let beneficiaryall: any = false;
    if (location?.search.indexOf("beneficiaryall") > -1) {
      beneficiaryall = new URLSearchParams(location?.search).get("beneficiaryall");
    }
    dispatch(
      summarySaveESignature(beneficiariesESignatureModal?.isAgreed || beneficiariesESignatureModal?.isSelected ? 1 : 0, beneficiariesESignatureModal?.verificationText, beneficiaryall, (response) => {
        if (response?.statusCode) {
          navigate(beneficiaryBackActionLink ? beneficiaryBackActionLink.backActionlink : "/Summary", navigateChangedState);
        }
      })
    );
  };

  const pageTitle = summaryBeneficiariesData?.tabName;
  const beneficiaryBenefitsInfo = summaryBeneficiariesData?.beneficiaryBenefitsInfo || [];
  const content = summaryBeneficiariesData?.contentResource || {};
  const contents = content.contents ? content.contents : {};
  const controlResource = content?.controlResource ? content?.controlResource?.Beneficiaries : {};
  const eSignatureConfirmation = summaryBeneficiariesData?.eSignatureConfirmation || {};
  const thcPercent = ObjectUtils.extractLabelFromObject(controlResource, "thcPercent");
  const btnSave = ObjectUtils.extractLabelFromObject(contents, "btnSave");
  const lblCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const beneficiaryBackActionLink = summaryBeneficiariesData?.beneficiaryBackActionLink || "";

  const handleVerificationText = (event: any) => {
    setBeneficiariesESignatureModal({
      ...beneficiariesESignatureModal,
      isAgreed: eSignatureConfirmation?.verifyTypeToSign === event.target.value ? true : false,
      verificationText: event?.target?.value
    });
  };

  const renderBeneficiarieList = (title: any, note: any, editButton: any, list: any) => {
    if (list && list.length > 0) {
      const finalTitle = note ? title + ` ${note}` : "";
      const items: any = [];
      list?.map((item: any) => {
        items.push(
          <tr key={`div-${title}-${editButton?.linkID}-ID-${item?.beneficiaryName}`}>
            <td dangerouslySetInnerHTML={{ __html: `${item?.beneficiaryName}` }} className="border-end-0" />
            {item?.removedMsg === "" || item?.removedMsg == null ? (
              <td className="text-end">{item?.percentage}%</td>
            ) : (
              <td className="text-end">
                <span className="ml-mandatory-sign">({item?.removedMsg})</span> {item?.percentage}%
              </td>
            )}
          </tr>
        );
      });

      return (
        <>
          <div className="tbl-beneficiaries-esign">
            <Table>
              <thead>
                <tr>
                  <th scope="col" className="border-end-0">
                    {title}
                  </th>
                  <th scope="col" className="text-end">
                    {thcPercent?.display}
                  </th>
                </tr>
              </thead>
              <tbody>
                {items}
                {finalTitle && (
                  <tr>
                    <td colSpan={2} className="pb-3 bg-white fw-500">
                      <AlertComponent propmessageType={`primary`} propmessage={finalTitle} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </>
      );
    }
    return null;
  };

  const renderNoBeneficiariesMessage = (title: any, message: any, thcPercent: any) => {
    return (
      <>
        <Card className={`ccard onfilebeneficiaries`}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div>
                <h2>{title}</h2>
              </div>
              <div>
                <h2>{thcPercent.display}</h2>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="bgrow" dangerouslySetInnerHTML={{ __html: message }} />
          </Card.Body>
        </Card>
      </>
    );
  };

  const handleBack = () => {
    navigate(beneficiaryBackActionLink?.backActionlink || "/Summary");
  };
  /* E-Signature Start */

  //Beneficiary Form Configuration
  const { beneficiaryMessage, noBeneficiaryBenefits, noCoverage } = summaryBeneficiariesData || "";
  document.title = pageTitle;
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const backButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnBack");
  const beneficiaryList: any = [];
  beneficiaryBenefitsInfo?.map((benefit: any) => {
    const onFile = renderBeneficiarieList(benefit?.lblOnFile, null, benefit?.editButton, benefit?.beneficiaryCurrentInfo);
    const pending = renderBeneficiarieList(benefit?.pending, benefit.pendingNote, benefit?.editButton, benefit?.beneficiaryPendingInfo);
    const contingentPending = renderBeneficiarieList(benefit?.lblContingentPending, benefit.pendingNote, benefit?.editButton, benefit?.contingentBeneficiaryPendingInfo);
    const contingentOnFile = renderBeneficiarieList(benefit?.lblContingentOnFile, null, benefit?.editButton, benefit?.contingentBeneficiaryCurrentInfo);
    const noBeneficiariesMessage = renderNoBeneficiariesMessage(benefit?.lblOnFile, benefit?.noBeneficiariesNote, thcPercent);
    beneficiaryList.push(
      <Card key={`div-${benefit.benefitID}-ID-${benefit.benefitName}`} className="mb-4 insurancetype pcard">
        <Card.Header>
          <h2 className="heading-white">{benefit.benefitName}</h2>
        </Card.Header>
        <Card.Body>
          {benefit.noBeneficiariesNote ? (
            <div className="clearfix">{noBeneficiariesMessage}</div>
          ) : (
            <>
              {onFile}
              {contingentOnFile}
              {pending}
              {contingentPending}
            </>
          )}
        </Card.Body>
      </Card>
    );
  });
  /* E-Signature Start */
  let disableSaveBtn: any = !beneficiariesESignatureModal?.isSelected;
  if (eSignatureConfirmation.enableTypeToSign) {
    disableSaveBtn = !beneficiariesESignatureModal?.isAgreed || !beneficiariesESignatureModal?.isSelected;
  }
  /* E-Signature End */
  const btnBack = [
    {
      display: backButtonText.display,
      variant: "link",
      rightpos: true,
      displayclass: "m-0 mx-3",
      className: "float-left",
      icon: faChevronLeft,
      onSubmit: handleBack
    }
  ];

  const cardFooterBtns = {
    handleCancel: [
      {
        variant: "outline-secondary btn-cancel",
        display: lblCancel.display,
        onSubmit: handleBack
      }
    ],
    handleSave: [
      {
        variant: "outline-secondary btn-sm btn-save",
        display: btnSave.display,
        disabled: disableSaveBtn,
        onSubmit: onSave
      }
    ]
  };
  return (
    <div className="summary summarybeneficiaries beneficiariesall baneficiarylist clearfix">
      <div className={`d-flex titlewithicon mb-3`}>
        <ButtonComponent btndata={btnBack} />
      </div>
      <div className="beneficiarylist clearfix mb-md-4">
        {noBeneficiaryBenefits && <AlertComponent propmessageType={`dark`} propmessage={noBeneficiaryBenefits} />}
        {noCoverage && <AlertComponent propmessageType={`secondary`} propmessage={noCoverage} />}
        {!noCoverage && !noBeneficiaryBenefits && (
          <Card className="mb-4 insurancetype pcard">
            <Card.Header>
              <h2 className="heading-white">{pageTitle}</h2>
            </Card.Header>
            <Card.Body className="pcard">{beneficiaryList}</Card.Body>
          </Card>
        )}

        {beneficiaryMessage && <AlertComponent propmessageType={`info`} propmessage={beneficiaryMessage} />}
        {eSignatureConfirmation && (
          <div className="esignable-container">
            <Container className="mb-3 pb-3 d-flex flex-wrap">
              <div className="scroll-container">
                <p
                  className="mb-3 form-check-label warning"
                  dangerouslySetInnerHTML={{
                    __html: eSignatureConfirmation.declaration
                  }}
                />
              </div>
            </Container>
            <div className="clearfix form-check">
              <input type="checkbox" key="confirmSelection" className="" id="confirmSelection" checked={beneficiariesESignatureModal?.isSelected} onChange={handleValue} />
              <label className="mb-3 pb-3 form-check-label warning" htmlFor="confirmSelection">
                {eSignatureConfirmation?.agreement}
              </label>
            </div>
            {eSignatureConfirmation?.enableTypeToSign && (
              <>
                <Form.Label
                  className="mb-3 form-check-label warning"
                  htmlFor="enableTypeToSignText"
                  dangerouslySetInnerHTML={{
                    __html: eSignatureConfirmation?.enableTypeToSignText
                  }}
                />
                <Form.Control
                  type="text"
                  aria-label={eSignatureConfirmation.enableTypeToSignText}
                  className="form-control login-input-field"
                  id="verificationText"
                  aria-labelledby="verificationText"
                  aria-required="true"
                  required
                  autoComplete="on"
                  value={beneficiariesESignatureModal?.verificationText}
                  onChange={handleVerificationText}
                />
              </>
            )}
            <div className="d-flex justify-content-between py-3">
              <ButtonComponent btndata={cardFooterBtns.handleCancel} />
              <ButtonComponent btndata={cardFooterBtns.handleSave} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryESignBeneficiaries;
