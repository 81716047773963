import { useCallback, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { confirmEnrollment, getBenefitSummary, getShowFlex, reallocateMultiBenefit } from "../../actions";
import { AlertComponent, ButtonComponent, DescriptionComponent, NextPrevComponent } from "../../components";
import { BenefitSummaryModal } from "../../interfaces/formValidation";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";

export const BenefitSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputElement = useRef<HTMLInputElement[]>([]);
  const scrollRef = useRef<HTMLInputElement>(null);
  const benefitSummary = useSelector((state: RootState) => state.benefit.benefitSummary);
  const [sessionBenefitSummary, setsessionBenefitSummary] = useState<BenefitSummaryModal>({
    isSelected: false,
    isEnabled: false,
    errorMessage: "",
    disableCheckBox: false
  });

  const getBenefitSummaryData = useCallback(() => {
    dispatch(getBenefitSummary(true));
  }, [dispatch]);

  useEffect(() => {
    //This gets app config data and save into redux store
    getBenefitSummaryData();
    sessionBenefitSummary.errorMessage && inputElement.current[0].focus();
  }, [getBenefitSummaryData, navigate, sessionBenefitSummary.errorMessage]);

  useEffect(() => {
    if (benefitSummary !== null) {
      const currentRef = scrollRef?.current;
      const scrollTop: any = currentRef?.scrollTop;
      const scrollHeight: any = currentRef?.scrollHeight;
      const clientHeight: any = currentRef?.clientHeight;
      console.log("scrollTop useEffect ===> : ", scrollTop);
      console.log("scrollHeight useEffect ===> : ", scrollHeight);
      console.log("clientHeight useEffect===> : ", clientHeight);
      const bottom: any = scrollHeight - scrollTop <= clientHeight;
      console.log("bottom useEffect ===> : ", bottom);
      if (bottom) {
        setsessionBenefitSummary({
          ...sessionBenefitSummary,
          disableCheckBox: false,
          isEnabled: true
        });
      } else if (benefitSummary?.isScrollableDisclaimer && !sessionBenefitSummary?.isSelected) {
        setsessionBenefitSummary({
          ...sessionBenefitSummary,
          disableCheckBox: benefitSummary?.isScrollableDisclaimer
        });
      }
    }
  }, [benefitSummary]);

  const handleSubmit = () => {
    dispatch(
      confirmEnrollment(
        async () => {
          dispatch(await getShowFlex());
          navigate("/EnrollmentConfirmation");
        },
        (_errorMessage: any) => {
          setsessionBenefitSummary({
            ...sessionBenefitSummary,
            errorMessage: _errorMessage
          });
        }
      )
    );
  };

  const reallocate = () => {
    const actionlink = benefitSummary.reallocation && benefitSummary?.reallocation && benefitSummary?.reallocation.actionlink ? benefitSummary?.reallocation?.actionlink : "/BeneficiaryReallocate";
    dispatch(reallocateMultiBenefit(-2));
    navigate(actionlink);
  };

  const navigateBeneficiaryESignature = () => {
    const actionlink =
      benefitSummary.beneficiaryESignature && benefitSummary?.beneficiaryESignature && benefitSummary?.beneficiaryESignature?.actionlink
        ? benefitSummary?.beneficiaryESignature?.actionlink
        : "Summary/BeneficiariesESignature?beneficiaryall=false";
    navigate(`/${actionlink}`);
  };

  const handleScroll = (event: any) => {
    const { scrollHeight, scrollTop, clientHeight,offsetHeight } = event.target;
    let bottom = scrollHeight - Math.round(scrollTop) <= clientHeight; 
    console.log("Scrolling START >>>");
    console.log("scrollTop with Math.round  ===> : ", Math.round(scrollTop));
    console.log("scrollTop ===> : ", scrollTop);
    console.log("scrollHeight ===> : ", scrollHeight);
    console.log("clientHeight ===> : ", clientHeight);
    console.log("bottom ===> : ", bottom);
    console.log("scrollHeight - Math.round(scrollTop) and clientHeight ===> : ", scrollHeight - Math.round(scrollTop),clientHeight);
    if (!bottom){
      bottom=scrollTop + offsetHeight>= scrollHeight; //handle 25% and 175 % as well
      console.log("Scroll End - New");
   }
    if (bottom) {
      setsessionBenefitSummary({
        ...sessionBenefitSummary,
        disableCheckBox: false,
        isEnabled: false
      });
    }
  };

  const handleValue = () => {
    setsessionBenefitSummary({
      ...sessionBenefitSummary,
      isSelected: !sessionBenefitSummary.isSelected
    });
  };

  const contents = benefitSummary?.contentResource?.controlResource?.EnrollmentFooter ? benefitSummary?.contentResource?.controlResource?.EnrollmentFooter : {};
  const benefitContents = benefitSummary?.contentResource?.controlResource?.Beneficiaries || {};
  const chartData = benefitSummary?.chart;
  const lblFlexAmountTitle = ObjectUtils.extractLabelFromObject(contents, "lblFlexAmount");
  const lblBenefitSummaryMessage = benefitSummary?.benefitSummaryMessage;
  const lblClickChartMessage = ObjectUtils.extractLabelFromObject(contents, "lblClickChartMessage");
  const lblChartListHeading = ObjectUtils.extractLabelFromObject(contents, "lblChartListHeading");
  const benefitSummaryTableInfo = ObjectUtils.extractLabelFromObject(benefitContents, "benefitSummaryTableInfo");
  const lblChartListLegend = ObjectUtils.extractLabelFromObject(contents, "lblChartListLegend");
  const lblPercentageAllocation = ObjectUtils.extractLabelFromObject(contents, "lblPercentageAllocation");
  const lblBenefitType = ObjectUtils.extractLabelFromObject(contents, "lblBenefitType");
  const lblAmount = ObjectUtils.extractLabelFromObject(contents, "lblAmount");
  const lblConfirmMessage = benefitSummary?.confirmMessage ? benefitSummary.confirmMessage : "";
  const lblBtnSubmit = ObjectUtils.extractLabelFromObject(contents, "btnSubmit");
  const lblBtnCancel = ObjectUtils.extractLabelFromObject(contents, "btnCancel");
  const beneficiaries = benefitSummary?.beneficiaries ? benefitSummary?.beneficiaries : {};
  const dependents = benefitSummary?.dependents ? benefitSummary?.dependents : {};
  const personal = benefitSummary?.personal ? benefitSummary?.personal : {};

  /* E-Signature Start */
  const isEsignable = benefitSummary?.isEsignable || false;
  const canSubmit = benefitSummary?.canSubmit || false;
  const lblEsignableTitle = ObjectUtils.extractLabelFromObject(contents, "lblEsignableTitle");
  const lblEsignableSuccess = ObjectUtils.extractLabelFromObject(contents, "lblEsignableSuccess");
  const lblEsignableMessage = ObjectUtils.extractLabelFromObject(contents, "lblEsignableMessage");
  const beneficiaryESignature = benefitSummary?.beneficiaryESignature ? benefitSummary?.beneficiaryESignature : null;
  /* E-Signature End */

  /* Reallocation Start */
  const reallocationButtonText = benefitSummary?.reallocation && benefitSummary?.reallocation?.text ? benefitSummary?.reallocation?.text : "";
  const removedBeneficiaryError = benefitSummary && benefitSummary?.allBenefitsAllocationError ? benefitSummary?.allBenefitsAllocationError : "";
  /* Reallocation End */

  /* Scrollable Disclaimer Start */
  const isScrollableDisclaimer = benefitSummary?.isScrollableDisclaimer || false;
  const disclaimerMessage = benefitSummary?.disclaimerMessage;
  const lblSummaryTermsAndConditions = ObjectUtils.extractLabelFromObject(benefitSummary?.contentResource?.contents, "lblSummaryTermsAndConditions");
  /* Scrollable Disclaimer End */

  let concatenateChartData = "";
  const chart: any = [];
  // _.forEach(chartData, (item, i) => {
  chartData?.map((item: any, i: any) => {
    const value: any = {};
    value["title"] = item.name;
    value["value"] = parseInt(item.percent.split("%")[0]);
    value["color"] = item.colorCode;
    value["textColor"] = item.colorName;
    value["amount"] = item.amountSpent;
    chart.push(value);
    concatenateChartData = concatenateChartData + "benefit " + value.title + " amount " + benefitSummary.chart[i].amountSpent + " percent " + benefitSummary.chart[i].percent + "\n";
  });
  const pageTitle = ObjectUtils.extractLabelFromObject(contents, "lblBenefitSummary");
  document.title = pageTitle.display;
  return (
    <div className="summary benefitsummary clearfix">
      {benefitSummary?.isFlexChartVisible && (
        <>
          <DescriptionComponent headingclassname="m-0" level="1" heading={lblFlexAmountTitle?.display} />
          <div className="ml-description mb-2 mb-md-4 clearfix">
            <p className="m-0">{lblBenefitSummaryMessage}</p>
          </div>
        </>
      )}
      {benefitSummary?.isFlexChartVisible && lblClickChartMessage !== "" && (
        <div className="ml-titledescription mb-3 mb-md-4 clearfix">
          <DescriptionComponent headingclassname="m-0" level="2" heading={lblClickChartMessage?.display} />
        </div>
      )}
      {benefitSummaryTableInfo !== "" && (
        <div className="ml-description mb-2 mb-md-4 clearfix">
          <p className="m-0">{benefitSummaryTableInfo.display}</p>
        </div>
      )}
      {benefitSummary?.isFlexChartVisible && concatenateChartData !== "" && (
        <>
          <div>
            <div className="summarychart clearfix" aria-hidden="true">
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-4">
                  <PieChart
                    animate={false}
                    animationDuration={500}
                    animationEasing="ease-out"
                    data={chart}
                    labelPosition={50}
                    lengthAngle={360}
                    lineWidth={100}
                    onClick={undefined}
                    paddingAngle={1.2}
                    radius={50}
                    rounded={false}
                    startAngle={0}
                    style={{
                      height: "30vh"
                    }}
                    viewBoxSize={[100, 100]}
                  />
                </div>
                <div className="col-md-6" style={{ marginTop: "28px" }}>
                  {benefitSummary?.isFlexChartVisible && lblChartListLegend !== "" && (
                    <div className="ml-description mb-3 mb-md-3 clearfix">
                      <div aria-label={lblChartListLegend.textToRead} className={`piechart-list-heading m-0`}>
                        {lblChartListLegend.display}
                      </div>
                    </div>
                  )}
                  {chart &&
                    chart?.map((item) => (
                      <div key={item.title} className="row">
                        <div className="col-spnlegend">
                          <div className="spnlegend" style={{ backgroundColor: item?.color }}></div>
                          <div className="spnlegendtext17">{item?.title}</div>
                          <div className="spnlegendtext10">- {item?.value}%</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="tbl-pieChart">
            <DescriptionComponent headingclassname="title" level="2" heading={lblChartListHeading?.display} />
            <Table className="table-striped">
              <thead>
                <tr>
                  <th scope="col">{lblPercentageAllocation.display}</th>
                  <th scope="col">{lblBenefitType.display}</th>
                  <th scope="col">{lblAmount.display}</th>
                </tr>
              </thead>
              <tbody>
                {chart &&
                  chart.map((item: any) => (
                    <tr key={item?.title}>
                      <td>{item?.value}%</td>
                      <td>{item?.title}</td>
                      <td>{item?.amount}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
      <div className="mb-3 pb-3 pb-md-4 mb-md-4 d-flex flex-wrap justify-content-center quicklinks">
        <ButtonComponent
          btndata={[
            {
              variant: "outline-secondary btn-beneficiaries",
              display: personal?.text,
              onSubmit: () => {
                navigate(`/${personal?.actionlink}`);
              }
            }
          ]}
        />
        <ButtonComponent
          btndata={[
            {
              variant: "outline-secondary btn-beneficiaries",
              display: beneficiaries?.text,
              onSubmit: () => {
                navigate(`/${beneficiaries?.actionlink}`);
              }
            }
          ]}
        />
        <ButtonComponent
          btndata={[
            {
              variant: "outline-secondary btn-dependents",
              display: dependents?.text,
              onSubmit: () => {
                navigate(`/${dependents?.actionlink}`);
              }
            }
          ]}
        />
      </div>
      {/* E-Signature Start */}
      {isEsignable && (
        <div className="mb-3 pb-3 pb-md-4 mb-md-4 d-flex flex-wrap quicklinks">
          <p className="bold-text-imp ml-popupmessagetext">{lblEsignableTitle.display}</p>
          {!canSubmit && <span className="mb-3 form-check-label warning" dangerouslySetInnerHTML={{ __html: lblEsignableMessage.display }} />}
          {canSubmit && <span className="mb-3 form-check-label warning w-100" dangerouslySetInnerHTML={{ __html: lblEsignableSuccess.display }} />}
          {!canSubmit && (
            <ButtonComponent
              btndata={[
                {
                  variant: "outline-secondary btn-beneficiaries",
                  display: beneficiaryESignature?.text,
                  onSubmit: navigateBeneficiaryESignature
                }
              ]}
            />
          )}
        </div>
      )}
      {/* E-Signature End */}
      {/* Reallocate Start */}
      {!isEsignable && !canSubmit && (
        <div className="clearfix mb-3 mb-md-4 quicklinks">
          {<AlertComponent propmessageType={`danger mb-2`} propmessage={removedBeneficiaryError} />}
          <div>
            <ButtonComponent
              btndata={[
                {
                  variant: "outline-secondary btn-beneficiaries",
                  display: reallocationButtonText,
                  onSubmit: reallocate
                }
              ]}
            />
          </div>
          <br />
        </div>
      )}
      {/* Reallocate End */}
      {isScrollableDisclaimer && disclaimerMessage && <div className="bold-text-imp ml-popupmessagetext align-left">{lblSummaryTermsAndConditions.display}</div>}
      {disclaimerMessage && (
        <div className="esignable-container">
          <div className="mb-3 pb-3 d-flex flex-wrap">
            <div className="scroll-container" ref={scrollRef} onScroll={(event: any) => handleScroll(event)}>
              <p
                className="mb-3 align-justify"
                dangerouslySetInnerHTML={{
                  __html: disclaimerMessage
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className="clearfix form-check confirmationcheck mb-3 mb-md-4">
        <input
          type="checkbox"
          key="confirmSelection"
          className=""
          id="confirmSelection"
          disabled={!sessionBenefitSummary?.isEnabled && sessionBenefitSummary?.disableCheckBox}
          checked={sessionBenefitSummary?.isSelected}
          onChange={handleValue}
        />
        <label className="form-check-label warning" htmlFor="confirmSelection">
          {lblConfirmMessage}
        </label>
      </div>
      {sessionBenefitSummary?.errorMessage !== "" && (
        <div className="pt-3 border-top">
          <div className="alert alert-danger" ref={(ref: HTMLInputElement) => (inputElement.current[0] = ref)} role="alert" dangerouslySetInnerHTML={{ __html: sessionBenefitSummary?.errorMessage }} />
        </div>
      )}
      <NextPrevComponent
        lastPage={sessionBenefitSummary?.errorMessage !== "" ? true : false}
        firstPage={true}
        nextClick={handleSubmit}
        nextDisabled={!sessionBenefitSummary?.isSelected || !canSubmit}
        lblNext={lblBtnSubmit}
        lblCancel={lblBtnCancel}
        lblPrevious={lblBtnCancel}
        showButtons={true}
        hideCancel={false}
      />
    </div>
  );
};
