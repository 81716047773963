import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSummaryBenefits, getSummaryFlex } from "../../actions";
import SummaryActiveSubscriptions from "../../components/Benefit/SummaryActiveSubscriptions";
import SummaryEoi from "../../components/Benefit/SummaryEoi";
import SummaryFlex from "../../components/Benefit/SummaryFlex";
import { RootState } from "../../reducers";

export const EnrollmentSummary = () => {
  const dispatch = useDispatch();
  const [selected, setselected] = useState<any>("");
  const enrollmentSummary = useSelector((state: RootState) => state.benefit.enrollmentSummaryBenefits);
  const flexSummary = useSelector((state: RootState) => state.benefit.enrollmentSummaryFlex);

  useEffect(() => {
    setselected("summaryTab");
    //This gets app config data and save into redux store
    dispatch(getSummaryBenefits(true));
    dispatch(getSummaryFlex());
  }, [dispatch]);

  /* const selectedTab = (selectedValue: any) => {
    setselected(selectedValue);
  }; */

  return (
    <>
      <span id="enrollment-summary" />
      {/* <Nav variant="tabs" className="tabsenrollment" defaultActiveKey={"summaryTab"} onSelect={(evKey: any) => setselected(evKey)} activeKey={selected} role="tablist">
        {enrollmentSummary?.tabName && (
          <Nav.Link className="nav-item" eventKey="summaryTab">
            {enrollmentSummary?.tabName || "noLblForTabName"}
          </Nav.Link>
        )}
        {flexSummary?.tabName && (
          <Nav.Link className="nav-item" eventKey="summaryflex">
            {flexSummary?.tabName || "noLblForSummaryFlexTabName"}
          </Nav.Link>
        )}
        {enrollmentSummary?.otherTabName && (
          <Nav.Link className="nav-item" eventKey="summaryOtherTab">
            {enrollmentSummary?.otherTabName || "noLblForOtherTabName"}
          </Nav.Link>
        )}
      </Nav>
      {selected === "summaryTab" && <SummaryActiveSubscriptions title={enrollmentSummary?.tabName || "noLblForTabName"} enrolmentSummaryData={enrollmentSummary} />}
      {flexSummary?.tabName && selected === "summaryflex" && <SummaryFlex title={flexSummary?.tabName || "noLblForSummaryFlexTabName"} flexSummaryData={flexSummary} />}
      {enrollmentSummary?.otherTabName && selected === "summaryOtherTab" && (
        <SummaryEoi title={enrollmentSummary?.otherTabName || "noLblForOtherTabName"} enrolmentSummaryEoiData={enrollmentSummary} />
      )} */}

      <Tabs className="tabsenrollment" activeKey={selected} onSelect={(evKey) => setselected(evKey)}>
        {enrollmentSummary?.tabName && (
          <Tab eventKey="summaryTab" title={enrollmentSummary?.tabName || "noLblForTabName"}>
            <SummaryActiveSubscriptions title={enrollmentSummary?.tabName || "noLblForTabName"} enrolmentSummaryData={enrollmentSummary} />
          </Tab>
        )}
        {flexSummary?.tabName && (
          <Tab eventKey="summaryflex" title={flexSummary?.tabName || "noLblForSummaryFlexTabName"}>
            <SummaryFlex title={flexSummary?.tabName || "noLblForSummaryFlexTabName"} flexSummaryData={flexSummary} />
          </Tab>
        )}
        {enrollmentSummary?.otherTabName && (
          <Tab eventKey="summaryOtherTab" title={enrollmentSummary?.otherTabName || "noLblForOtherTabName"}>
            <SummaryEoi title={enrollmentSummary?.otherTabName || "noLblForOtherTabName"} enrolmentSummaryEoiData={enrollmentSummary} />
          </Tab>
        )}
      </Tabs>
    </>
  );
};
export default EnrollmentSummary;
