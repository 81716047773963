import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWellness } from "../../actions";
import { ObjectUtils } from "../../utils";
//import { RootState } from "../../reducers";
import { TableComponent } from "../../components/Form/TableComponent";
import { RootState } from "../../reducers";
import { AlertComponent } from "../../components";

interface Answer {
  questionID: string;
  selectionValue: number;
  yesSelected: boolean;
  viewingOrder: number;
  linked: string;
  supplementalAnswer: string;
  supplementalAnswerEnabled: boolean;
}

export const BenefitWellness = () => {
  const dispatch = useDispatch();
  // const [benefitWellness, setWellnessData] = useState<any>();
  const { benefitWellness, wellnessError } = useSelector((state: RootState) => state.benefit);

  const [questionsList, setQuestionsList] = useState<any[]>(benefitWellness?.result || []);

  useEffect(() => {
    // Update questionsList when benefitWellness is available and not null/undefined
    if (benefitWellness && benefitWellness.result) {
      setQuestionsList(benefitWellness.result);
    }
  }, [benefitWellness]); // Add benefitWellness as a dependency

  useEffect(() => {
    async function fetchBenefitWellness() {
      dispatch(await getWellness());
    }
    fetchBenefitWellness();
  }, [dispatch]);

  const changeOptionAnswer = (index: number): void => {
    const answers: Answer[] = [...questionsList];

    answers[index].selectionValue = answers[index].yesSelected ? 0 : 1;

    answers[index].yesSelected = !answers[index].yesSelected;

    answers?.map((answer) => {
      if (answer.questionID !== answers[index].questionID && answer.viewingOrder === answers[index].viewingOrder && answer.linked === answers[index].linked) {
        answer.yesSelected = false;
        answer.selectionValue = 0;
      }
      return answer;
    });

    if (!answers[index].yesSelected) {
      answers[index].supplementalAnswer = "";
      answers[index].supplementalAnswerEnabled = false;
    } else {
      answers[index].supplementalAnswerEnabled = true;
    }
    setQuestionsList(answers);
  };

  const changeInput = (callback: any) => {
    const key: any = Object.keys(callback)[0];
    const value: any = Object.values(callback)[0];
    const answers = [...questionsList];
    answers[key - 1].supplementalAnswer = value;

    setQuestionsList(answers);
  };

  const gridRowBodyObject: any = [];
  questionsList?.map((item: any, index: number) => {
    const viewSupplement = item?.supplementalQuestionRequired && (item?.isSupplemental || item?.showSupplemental);

    const gridRowData = [
      {
        type: "text",
        display: item?.question,
        name: `${item?.supplementalQuestion}-${item.questionID}-${index}`,
        mode: "view",
        colclassname: "col-6 col"
      },
      {
        type: "text",
        display: item.showlblWellnessAmount ? `$${item.calculatedCredits}` : "\u00A0",
        name: `${item?.supplementalQuestion}-${item.questionID + 1}-${index}`,
        mode: "view",
        colclassname: "col-3 col ps-4"
      },
      {
        type: "choicelist",
        colclassname: "col-3 col ps-5",
        mode: "edit",
        fgclass: "mt-2 mb-4 float-left custom-control custom-switch",
        skip: item?.yesEnabled ? null : "1",
        options: {
          type: "switch",
          ischecked: true,
          name: `opt-id-${item.questionID}`,
          selected: item?.yesSelected,
          id: `opt-id-${item.questionID}`,
          className: "custom-control-input",
          fgclass: "mt-2 mb-4",
          handlechange: () => {
            changeOptionAnswer(index);
          },

          optionsinput: [
            {
              value: true,
              text: item?.question
            }
          ]
        }
      },
      ,
      viewSupplement && {
        type: "text",
        viewSupplement,
        value: item?.supplementalAnswer ? item.supplementalAnswer : "",
        display: item?.supplementalQuestion || "",
        texttoread: item?.supplementalAnswer || "",
        // name: `${item?.supplementalQuestion}-${item.questionID + 1}-${index}`,
        name: `${index + 1}`,
        handlechange: changeInput,
        skip: viewSupplement ? null : "1",
        mode: "edit",
        fgclass: "row",
        lblclassname: "px-5 col-6 align-justify col-form-label",
        gridrow: "col-sm-3 col-6 offset-sm-3",
        disabled: !item.supplementalAnswerEnabled
      }
    ];

    gridRowBodyObject.push(gridRowData);
  });
  const wellness = benefitWellness;
  const contents = benefitWellness?.contentResource?.contents;
  // UI Display Lables
  const wellnessQuestionsTitle = wellness?.wellnessQuestionsTitle; //getDisplayText(wellness, "wellnessQuestionsTitle");
  const noWellnessQuestionstionsNote = wellness?.noWellnessQuestionstionsNote; //getDisplayText(wellness, "noWellnessQuestionstionsNote");
  const wellnessQuestionsNote = wellness?.wellnessQuestionsNote; //getDisplayText(wellness, "wellnessQuestionsNote");
  const wellnessConfigMessage = wellness?.wellnessConfigMessage;
  const wellnessMessages = `${wellnessQuestionsNote ? wellnessQuestionsNote : ""} ${wellnessConfigMessage ? wellnessConfigMessage : ""}`;
  const wellnessQuestionCardTitleValueLabel = ObjectUtils.extractLabelFromObject(contents, "lblValue");
  const lblNoYesHeader = ObjectUtils.extractLabelFromObject(contents, "lblNoYesHeader");
  const isNoWellnessQuestionsMsg = wellness?.isNoWellnessQuestionsMsg;

  const tableObject = {
    className: "wellnesscard card",
    header: {
      headerData: [
        {
          headername: wellnessQuestionCardTitleValueLabel.display,
          isWellnessValue: true,
          headerColumnClass: "col-height col-3 offset-6"
        },
        {
          headername: lblNoYesHeader.display,
          headerColumnClass: "col-height col-3"
        }
      ],
      headerClass: "card-header",
      headerRowClass: "row"
    },
    body: {
      bodyClass: "card-body",
      bodyRowClass: "card-row row",
      gridRowData: gridRowBodyObject
    },
    footer: ((wellnessMessages && wellnessMessages !== " " && !isNoWellnessQuestionsMsg) || (noWellnessQuestionstionsNote && isNoWellnessQuestionsMsg)) && {
      footClass: "card-footer",
      footLabel: isNoWellnessQuestionsMsg ? noWellnessQuestionstionsNote : wellnessMessages
    }
  };

  return (
    <>
      <div className={`ml-description mb-4 clearfix`}>
        <p className={`m-0`} dangerouslySetInnerHTML={{ __html: wellnessQuestionsTitle }} />
      </div>
      {gridRowBodyObject ? <TableComponent tableObject={tableObject}></TableComponent> : null}

      {wellnessError?.errorMessage && <AlertComponent propmessageType={`danger`} propmessage={wellnessError?.errorMessage} />}
    </>
  );
};

export default BenefitWellness;
